<template>
  <div class="w-full">
    <div class="flex flex-col w-72 h-full mx-auto">
      <div class="flex-grow flex flex-col justify-center">
        <LogoConfirmed />
        <h3 class="block text-center">
          {{ $t("message.your-account-is-ready") }}
        </h3>
        <div class="font-body mt-4 text-center">
          <p>{{ $t("message.we-are-glad-youre-here") }}</p>
          <p>{{ $t("message.now-its-time-for-action") }}</p>
        </div>
      </div>
      <button
        tabindex="0"
        class="mx-auto btn btn-lg btn-default mt-6 mb-8"
        @click="next()"
      >
        <span class="mx-auto">
          {{ $t("message.done") }}
        </span>
      </button>
      <NeedHelp />
    </div>
  </div>
</template>

<script>
import LogoConfirmed from "@/components/LogoConfirmed.vue";
import NeedHelp from "@/components/register/NeedHelp.vue";

export default {
  name: "AccountVerified",
  components: { LogoConfirmed, NeedHelp },
  methods: {
    next() {
      if (this.$store.getters["auth/isLogged"]) {
        this.$router.push({ name: "HomeIn" });
      } else {
        this.$router.push({ name: "SignInPage" });
      }
    }
  }
};
</script>
