<template>
  <InfiniteScroll
    class="h-full"
    :loading="loading"
    @loadMore="loadMore"
    :hasMoreToLoad="hasMoreToLoad"
  >
    <div
      class="container mx-auto relative w-full flex flex-row mt-6 h-11 mb-6 max-w-desktop"
    >
      <BackButton
        type="dark"
        @click="viewTeam($route.params.slug)"
        class="absolute left-4 top-0"
      />
      <div class="flex flex-grow justify-center">
        <h3 class="capitalize">
          {{ title }}
        </h3>
      </div>
    </div>
    <template v-if="!loading || (profilesList && profilesList.length > 0)">
      <div
        id="follower-list"
        class="grid grid-col-1 gap-4 scrollbar-hidden w-full overflow-x-auto mb-4 px-4"
        :class="{ 'bg-white border-solid rounded-md px-14 py-12': isDesktop }"
      >
        <div
          v-for="(profile, index) in profilesList"
          :key="index"
          class="flex flex-row w-full items-center grid grid-profile-row"
        >
          <div
            class="flex flex-row items-center overflow-hidden cursor-pointer"
          >
            <Avatar
              avatarType="small"
              :displayName="profile.displayName"
              :profileImageUrl="profile.profileImageUrl"
              @click="viewProfile(profile.screenName)"
            />
            <div
              class="flex-grow flex flex-col ml-2 overflow-hidden"
              @click="viewProfile(profile.screenName)"
            >
              <h6 class="text-sm sm:text-base truncate">
                {{ profile.displayName }}
              </h6>
              <p class="font-tiny truncate">@{{ profile.screenName }}</p>
            </div>
          </div>
          <div class="flex justify-end flex-row">
            <h4>
              {{
                standardizeAppsyncPoints(profile.pointsAwarded)
                  | livesImpactedFormat
              }}
            </h4>
            <img
              src="@/assets/img/icons/heart.svg"
              alt="heart"
              class="w-5 h-5 ml-2"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="loading">
      <TeamFollowersPageSkeleton />
    </template>
    <template v-else>
      <div class="h-full w-full">
        <NotFound :title="noFollowersText" :showLogo="false" />
      </div>
    </template>
  </InfiniteScroll>
</template>

<script>
import Avatar from "@/components/shared/Avatar.vue";
import BackButton from "@/components/shared/BackButton.vue";
import TeamFollowersPageSkeleton from "@/components/skeletons/TeamFollowersPageSkeleton.vue";
import NotFound from "@/components/shared/NotFound.vue";
import InfiniteScroll from "@/components/shared/InfiniteScroll.vue";
import {
  GET_TEAM_QUERY,
  GET_TEAM_MEMBERS
} from "@/graphql/queries/user/userQueries.js";
import { mapGetters } from "vuex";
import { standardizeAppsyncPoints } from "@/utils/basicHelpers.js";

export default {
  name: "TeamMembersPage",
  components: {
    Avatar,
    BackButton,
    TeamFollowersPageSkeleton,
    NotFound,
    InfiniteScroll
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    view() {
      return this.$route.meta.view;
    },
    title() {
      return this.$t("message.team-members");
    },
    noFollowersText() {
      return this.$t("message.no-team-members-yet");
    },
    profiles() {
      if (this.isViewFollowers) {
        return this.getMembers;
      } else {
        return this.getMembers;
      }
    },
    hasMoreToLoad() {
      return this.profiles?.nextToken ? true : false;
    },
    loadLimit() {
      return 20;
    },
    isDesktop() {
      return window.innerWidth > 768;
    }
  },
  data() {
    return {
      profilesList: [],
      teamId: null,
      loading: true,
      nextToken: null
    };
  },
  apollo: {
    getTeam: {
      query: GET_TEAM_QUERY,
      update(data) {
        this.teamId = data.getTeam.id;
      },
      variables() {
        return {
          slug: this.$route.params.slug
        };
      },
      skip() {
        return this.teamId != null;
      },
      fetchPolicy: "no-cache"
    },
    getMembers: {
      query: GET_TEAM_MEMBERS,
      update(data) {
        this.profilesList.push(...data.getTeamMembers.profiles);
        this.$nextTick(() => {
          this.loading = false;
        });
        return data.getTeamMembers;
      },
      variables() {
        return {
          limit: this.loadLimit,
          teamId: this.teamId,
          nextToken: this.nextToken
        };
      },
      skip() {
        return !this.teamId;
      },
      fetchPolicy: "no-cache"
    }
  },
  methods: {
    standardizeAppsyncPoints,
    loadMore() {
      if (!this.loading) {
        this.loading = true;
        this.nextToken = this.getMembers.nextToken;
      }
    },
    isLoggedUser(id) {
      if (this.currentUser?.id == id) {
        return true;
      } else {
        return false;
      }
    },
    viewProfile(screenName) {
      this.$router.push({
        name: "ProfilePage",
        params: { screenName }
      });
    },
    viewTeam(slug) {
      this.$router.push({
        name: "TeamPage",
        params: { slug }
      });
    }
  }
};
</script>

<style lang="postcss" scoped>
.btn-follow {
  font-size: var(--button-font-size-xs);
  border-radius: 80px;
  height: 34px;
}

#follower-list > div:not(:last-child) {
  border-bottom: 1px solid #f0f0e8;

  @apply pb-4;
}

@media screen and (min-width: 640px) {
  .btn-follow {
    font-size: var(--button-font-size-sm);
    height: 38px;
  }
}
.grid-profile-row {
  grid-template-columns: 1fr 150px;
}
</style>
