<template>
  <SettingsContainer :header="$t('message.change-password')">
    <template v-if="!passwordUpdated">
      <PasswordNew
        :showBackButton="false"
        @confirmedPassword="changePassword"
        :loading="loading"
        :isChangePassword="true"
        class="md:w-1/2 md:mx-auto"
        :buttonText="$t('message.continue')"
      />
      <template v-if="response">
        {{ response }}
      </template>
    </template>
    <template v-else>
      <PasswordUpdated
        ctaRoute="AccountSettings"
        :ctaText="$t('message.done')"
      />
    </template>
  </SettingsContainer>
</template>
<script>
import SettingsContainer from "@/components/settings/SettingsContainer.vue";
import PasswordNew from "@/components/shared/PasswordNew.vue";
import PasswordUpdated from "@/components/shared/PasswordUpdated.vue";
import { Auth } from "@aws-amplify/auth";
var formValidatorMixin = {};
export default {
  components: {
    SettingsContainer,
    PasswordNew,
    PasswordUpdated
  },
  mixin: [formValidatorMixin],
  data() {
    return {
      response: null,
      loading: false,
      passwordUpdated: false
    };
  },
  computed: {
    userIsLogged() {
      return this.$store.getters["auth/isLogged"];
    }
  },
  methods: {
    changePassword(oldPassword, password) {
      this.response = null;
      this.loading = true;
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, oldPassword, password);
        })
        .then(data => {
          console.log(data);
          this.response = `password updated`;
          this.passwordUpdated = true;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.response = "Error on request";
          this.passwordUpdated = false;
          this.loading = false;
        });
    }
  }
};
</script>
