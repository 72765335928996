import gql from "graphql-tag";

export const KENTICO_GET_HOME_TEAMS = gql`
  query homePageTeams($codename: String!) {
    homePageTeams(codename: $codename) {
      teams {
        items {
          buttonText
          colorHexCode
          description
          header
          teamSlug
          imageDesktop {
            url
          }
          imageMobile {
            url
          }
          textPosition {
            items {
              _system_ {
                codename
                name
              }
            }
          }
        }
      }
    }
  }
`;
