<template>
  <div class="w-full h-full flex flex-col relative md:pt-6">
    <NotFound
      :title="$t('message.page-not-found')"
      :description="$t('message.page-not-found-description')"
    />
  </div>
</template>

<script>
import NotFound from "@/components/shared/NotFound";

export default {
  components: {
    NotFound
  }
};
</script>
