<template>
  <InfiniteScroll
    :loading="loading"
    @loadMore="loadMore"
    :hasMoreToLoad="nextToken ? true : false"
  >
    <div class="container md:hidden">
      <LogoHeader colorLogo="blue" :showBackButton="true" />
    </div>
    <div class="px-4 md:mt-8">
      <h1 class="capitalize">
        {{ $t("message.discover") }}
      </h1>
      <div class="md:grid md:grid-cols-2 md:gap-4 md:grid-flow-row">
        <template v-if="!localTeams">
          <TeamCard
            v-for="(n, index) in 5"
            :key="index"
            :team="{}"
            :isPlaceholder="true"
          />
        </template>
        <template v-else>
          <TeamCard
            class=""
            v-for="team in localTeams"
            :key="team.slug"
            :team="team"
          />
        </template>
      </div>
    </div>
  </InfiniteScroll>
</template>
<script>
import { GET_TEAMS_QUERY } from "@/graphql/queries/user/userQueries.js";
import TeamCard from "@/components/shared/TeamCard.vue";
import LogoHeader from "@/components/shared/LogoHeader.vue";
import InfiniteScroll from "@/components/shared/InfiniteScroll.vue";

export default {
  data() {
    return {
      skipOtherNameQuery: false,
      nextToken: null,
      loading: true,
      localTeams: []
    };
  },
  components: {
    TeamCard,
    LogoHeader,
    InfiniteScroll
  },
  apollo: {
    teams: {
      query: GET_TEAMS_QUERY,
      update(data) {
        this.localTeams.push(...data.getTeams.teams);
        this.$nextTick(() => {
          this.loading = false;
          this.nextToken = data.getTeams.nextToken;
        });
        return data.getTeams;
      },
      //test if this property is needed
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          nextToken: this.nextToken
        };
      },
      skip() {
        return this.loading === false;
      }
    }
  },
  methods: {
    loadMore() {
      if (!this.loading) {
        this.loading = true;
        console.log("loadMore");
      }
    }
  }
};
</script>
<style lang="postcss" scoped>
.other-card {
  height: 302px;
}
</style>
