export default [
  {
    displayName: "Neymar Jr",
    screenName: "NeymarJr0123",
    type: "captain"
  },
  {
    displayName: "Lewis Hamilton",
    screenName: "NeymarJr0123",
    type: "captain"
  },
  {
    displayName: "Antoine Griezmann",
    screenName: "NeymarJr0123",
    type: "captain"
  },

  {
    displayName: "Winnie Harlow",
    screenName: "NeymarJr0123",
    type: "captain"
  },
  {
    displayName: "Skylar Diggins-Smith",
    screenName: "NeymarJr0123",
    type: "captain"
  },
  {
    displayName: "Virat Kohli",
    screenName: "NeymarJr0123",
    type: "captain"
  },
  {
    displayName: "Cara Delevigne",
    screenName: "NeymarJr0123",
    type: "captain"
  },
  {
    displayName: "Usian Bolt",
    screenName: "NeymarJr0123",
    type: "captain"
  },
  {
    displayName: "Dua Lipa",
    screenName: "NeymarJr0123",
    type: "captain"
  },
  {
    displayName: "Dixie D'Amelio",
    screenName: "NeymarJr0123",
    type: "captain"
  },
  {
    displayName: "Pep Guardiola",
    screenName: "NeymarJr0123",
    type: "captain"
  }
];
