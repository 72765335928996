<template>
  <div class="mx-auto flex flex-grow flex-col w-full">
    <h2 class="capitalize mx-auto flex justify-center">
      {{ $t("message.request-verification-code") }}
    </h2>
    <div class="flex-grow">
      <form @submit.prevent="next()">
        <InputWrapper
          class="mt-4"
          name="email"
          :label="$t('message.your-email-address')"
          type="email"
          :error="hasError('email')"
          :touched="touched('email')"
          :placeholder="$t('message.email-address')"
          :hint="hint('email')"
          v-model="email"
          @input="validateForm()"
          @blur="changed('email')"
        />
        <div class="mt-8 text-red text-center" v-if="response">
          {{ response }}
        </div>
        <button type="submit" class="mx-auto btn btn-lg btn-default my-4">
          <span class="mx-auto" v-if="!loading">
            {{ $t("message.submit-code-request") }}
          </span>
          <div class="loading w-6 h-6" v-else>
            <Spinner class="sm-button" />
          </div>
        </button>
      </form>
      <div class="flex flex-col">
        <router-link
          :to="{ name: 'RecoverPassword' }"
          class="w-max mx-auto text-center mt-6 text-blue-900 uppercase font-button cursor-pointer"
        >
          {{ $t("message.forgot-your-password-question") }}
        </router-link>
        <router-link
          :to="{ name: 'SignInPage' }"
          class="w-max mx-auto text-center mt-6 text-blue-900 uppercase font-button cursor-pointer"
        >
          {{ $t("message.member-log-in") }}
        </router-link>
        <router-link
          :to="{ name: 'SignUpEmailForm' }"
          class="w-max mx-auto text-center mt-6 text-blue-900 uppercase font-button cursor-pointer"
        >
          {{ $t("message.sign-up-for-account") }}
        </router-link>
      </div>
    </div>
    <NeedHelp />
  </div>
</template>

<script>
import InputWrapper from "@/components/form/InputWrapper.vue";
import Spinner from "@/components/shared/Spinner.vue";
import NeedHelp from "@/components/register/NeedHelp.vue";
import { Auth } from "@aws-amplify/auth";
import { isValidEmailFormat, isValidEmailLength } from "@/utils/basicHelpers";

export default {
  name: "EnterCode",
  components: {
    InputWrapper,
    Spinner,
    NeedHelp
  },
  data: () => {
    return {
      loading: false,
      email: "",
      error: "",
      validation: {},
      showAllErrors: false,
      response: null,
      step: 1
    };
  },
  computed: {
    touched() {
      return key => {
        return this.validation[key]?.touched == true;
      };
    },
    hasError() {
      return key => {
        return (
          (this.validation[key]?.error == true &&
            (this.showAllErrors == true ||
              this.validation[key]?.touched == true)) ||
          false
        );
      };
    },
    hint() {
      return key => {
        return this.validation[key]?.touched || this.showAllErrors
          ? this.validation[key]?.hint || ""
          : "";
      };
    }
  },
  mounted() {
    this.email = this.$route.params.emailCache;
    if (this.email) {
      this.emailCached = true;
    } else {
      this.email = "";
    }
  },
  methods: {
    changed(key) {
      if (!this.validation[key]) {
        this.validation[key] = {
          touched: true
        };
      } else {
        if (this.validation[key].touched == null) {
          this.validation[key].touched = true;
        } else {
          this.validation[key] = { ...this.validation[key], touched: true };
        }
      }
      this.showAllErrors = true;
      this.validateForm();
    },
    addError(key, message) {
      if (this.validation[key]) {
        this.validation[key].error = true;
        this.validation[key].hint = message;
      } else {
        this.validation[key] = {
          hint: message,
          error: true
        };
      }
    },
    clearError(key) {
      if (this.validation[key]) {
        this.validation[key].error = false;
        this.validation[key].hint = "";
      }
    },
    resetForm() {
      this.validation = {};
    },
    validateForm() {
      let valid = true;
      if (!this.emailCached) {
        if (this.email == "") {
          this.addError("email", "The email can't be empty");
          valid = false;
        } else if (!isValidEmailLength(this.email)) {
          this.addError("email", "Email is too long");
          valid = false;
        } else if (!isValidEmailFormat(this.email)) {
          this.addError("email", "Invalid email format");
          valid = false;
        } else {
          this.clearError("email");
        }
      }
      return valid;
    },
    async next() {
      this.response = null;
      this.showAllErrors = true;
      const valid = this.validateForm();
      if (valid) {
        try {
          this.loading = true;
          await Auth.resendSignUp(this.email.toLowerCase());
          this.loading = false;
          this.$router.push({
            name: "VerifyCode",
            params: {
              emailCache: this.email
            }
          });
        } catch (err) {
          console.log("Code", err.code);
          this.response = this.$t(`message.cognito-${err.code}`, {
            code: err.code,
            message: err.message
          });
          this.loading = false;
        }
      }
    }
  }
};
</script>
