import { render, staticRenderFns } from "./PostVideoUploader.vue?vue&type=template&id=67254d72&scoped=true&"
import script from "./PostVideoUploader.vue?vue&type=script&lang=js&"
export * from "./PostVideoUploader.vue?vue&type=script&lang=js&"
import style0 from "./PostVideoUploader.vue?vue&type=style&index=0&id=67254d72&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67254d72",
  null
  
)

export default component.exports