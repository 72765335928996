<template>
  <InfiniteScroll
    :loading="appsyncLoading"
    @loadMore="loadMore"
    :hasMoreToLoad="
      appsyncMoreTasks && appsyncMoreTasks.nextToken ? true : false
    "
  >
    <div>
      <div
        class="container mx-auto relative w-full flex flex-row mt-6 h-11 mb-6 max-w-desktop"
      >
        <BackButton
          type="dark"
          @click="$router.back()"
          class="absolute left-4 top-0"
        />
        <div class="flex flex-grow justify-center">
          <h3 class="capitalize">
            {{ $t("message.give-time") }}
          </h3>
        </div>
      </div>
      <div class="task-box-parent">
        <TaskLink
          class="task-box"
          v-for="(task, index2) in tasks"
          :key="index2"
          :task="task"
        />
      </div>
    </div>
  </InfiniteScroll>
</template>
<script>
import InfiniteScroll from "@/components/shared/InfiniteScroll.vue";
import TaskLink from "@/components/tasks/TaskLink.vue";
import {
  GET_TEAM_ID,
  GET_TEAM_TASKS
} from "@/graphql/queries/user/userQueries.js";
import { KENTICO_GET_TEAM_AND_TASKS } from "@/kentico-api/teams.js";
import BackButton from "@/components/shared/BackButton.vue";

export default {
  name: "TeamTasks",
  components: {
    BackButton,
    TaskLink,
    InfiniteScroll
  },
  data() {
    return {
      appsyncLoading: true,
      errorLoadingTeam: false,
      kenticoLoading: false,
      kenticoTeam: null,
      teamId: null,
      kenticoCodename: null,
      nextToken: null,
      tasks: []
    };
  },
  apollo: {
    appsyncTeam: {
      query: GET_TEAM_ID,
      update(data) {
        let team;
        if (data.getTeam) {
          team = data.getTeam;
          this.teamId = team.id;
          this.kenticoCodename = team.kenticoCodename;
        } else {
          this.errorLoadingTeam = true;
          this.appsyncLoading = false;
        }
        return team;
      },
      variables() {
        return {
          slug: this.$route.params.slug
        };
      },
      // `cache-and-network` results in the `update` method being called twice
      // `no-cache` results in the `update` method being called once
      fetchPolicy: "no-cache"
    },
    appsyncMoreTasks: {
      query: GET_TEAM_TASKS,
      update(data) {
        let team;
        if (data.getVisibleTeamTasks) {
          team = data.getVisibleTeamTasks;
          this.getKenticoTeamAndTasks(
            this.kenticoCodename,
            this.getKenticoTaskCodenames(team.tasks)
          );
        } else {
          this.errorLoadingTeam = true;
        }
        this.appsyncLoading = false;
        return team;
      },
      variables() {
        return {
          teamId: this.teamId,
          nextToken: this.nextToken,
          limit: 40
        };
      },
      skip() {
        return !this.teamId;
      },
      fetchPolicy: "no-cache"
    }
  },
  methods: {
    loadMore() {
      this.nextToken = this.appsyncMoreTasks.nextToken;
    },
    pushTasks(tasks) {
      if (!this.appsyncMoreTasks) return null;

      // kenticoTeam.tasks.items lists tasks in the order we want to display them
      tasks.forEach(kenticoTask => {
        const appsyncTask = this.appsyncMoreTasks.tasks.find(appsyncTask => {
          return appsyncTask.kenticoCodename === kenticoTask._system_.codename;
        });

        if (appsyncTask) {
          this.tasks.push({
            ...kenticoTask,
            id: appsyncTask.id,
            pointsOnCompletion: appsyncTask.pointsOnCompletion,
            progress: appsyncTask.progress,
            taskType: appsyncTask.taskType,
            taskCompletionsCount: appsyncTask.taskCompletionsCount
          });
        }
      });
    },
    getKenticoTeamAndTasks(teamCodename, taskCodenames) {
      this.$apollo
        .query({
          client: "kenticoClient",
          query: KENTICO_GET_TEAM_AND_TASKS,
          variables: {
            teamCodename: teamCodename,
            taskCodenames: taskCodenames
          },
          fetchPolicy: "no-cache"
        })
        .then(response => {
          if (response.data.team) {
            this.pushTasks(response.data.team.tasks.items);
          } else {
            this.errorLoadingTeam = true;
          }
          this.kenticoLoading = false;
        });
    },
    getKenticoTaskCodenames(tasks) {
      if (tasks.length == 0) return [""];
      else return tasks.map(task => task.kenticoCodename);
    }
  }
};
</script>
<style lang="postcss">
.task-box-parent {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  @media screen and (max-width: 840px) {
    @apply mx-4;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media screen and (max-width: 620px) {
    @apply mx-4;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media screen and (max-width: 450px) {
    grid-gap: 8px;
    .task-box {
      width: 100%;
      a {
        width: 100% !important;
      }
    }
  }
}
.task-box {
  @apply mx-0;
}
</style>
