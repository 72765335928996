<template>
  <SettingsContainer :header="$t(`message.zendesk-forms.report.header`)">
    <div class="md:w-1/2 md:mx-auto">
      <template v-if="!isZendeskApiEnabled">
        <h5 class="mb-2">Sorry</h5>
        <p class="font-body2 text-red">
          Zendesk is not configured on this GOL instance.
        </p>
      </template>
      <template v-else-if="!requestSent">
        <h5 class="mb-2">
          {{ $t(`message.zendesk-forms.report.sub-header`) }}
        </h5>
        <p class="font-body2">
          {{ $t(`message.zendesk-forms.report.sub-text`) }}
        </p>
        <form @submit.prevent="submitTicket()" class="mt-4">
          <InputWrapper
            name="emailAddress"
            class="mb-6"
            :label="$t('message.email-address')"
            type="text"
            :error="emailAddress.error"
            :touched="emailAddress.touched"
            :hint="emailAddress.hint"
            v-model="emailAddress.value"
            @blur="inputChanged('emailAddress')"
          />
          <InputWrapper
            name="Name"
            class="mb-6"
            :label="$t('message.name')"
            type="text"
            :error="name.error"
            :touched="name.touched"
            :hint="name.hint"
            v-model="name.value"
            @blur="inputChanged('name')"
          />
          <InputWrapper
            v-if="!isSubjectFullyPrepopulated"
            name="Subject"
            class="mb-6"
            :label="$t('message.subject')"
            type="text"
            :error="subject.error"
            :touched="subject.touched"
            :hint="subject.hint"
            v-model="subject.value"
            @blur="inputChanged('subject')"
          />
          <template v-else>
            <label for="Subject" class="block capitalize font-subtitle2 mb-4">
              {{ $t("message.subject") }}
            </label>
            <p class="mb-6">{{ subject.value }}</p>
          </template>
          <InputWrapper
            name="YourQuery"
            class="mb-6"
            :label="$t(`message.zendesk-forms.report.query`)"
            type="textarea"
            :error="yourQuery.error"
            :touched="yourQuery.touched"
            :hint="yourQuery.hint"
            v-model="yourQuery.value"
            @blur="inputChanged('yourQuery')"
          />
          <template v-if="zendeskError">
            <p class="text-red">
              There was a problem processing your request:
            </p>
            <p class="text-red">{{ zendeskError }}</p>
          </template>
          <button type="submit" class="btn btn-lg btn-default mt-8 mb-8">
            <span class="mx-auto" v-if="!loading">
              {{ $t("message.send") }}
            </span>
            <div class="loading w-6 h-6" v-else>
              <Spinner class="sm-button" />
            </div>
          </button>
        </form>
      </template>
      <template v-else>
        <h5 class="mb-2">
          {{ $t(`message.zendesk-forms.report.confirm-header`) }}
        </h5>
        <p class="font-body2">
          {{ $t(`message.zendesk-forms.report.confirm-text`) }}
        </p>
      </template>
    </div>
  </SettingsContainer>
</template>

<script>
import SettingsContainer from "@/components/settings/SettingsContainer.vue";
import InputWrapper from "@/components/form/InputWrapper.vue";
import Spinner from "@/components/shared/Spinner.vue";
import { GET_PROFILE_SUPPORT_FORM } from "@/graphql/queries/user/userQueries.js";
import { mapGetters } from "vuex";
import { validateField, validateObject } from "@/utils/validateObject.js";
import {
  zendeskEnabled,
  zendeskCreateRequest
} from "@/utils/zendeskSupportRequest.js";

const inputFields = [
  {
    name: "emailAddress",
    validators: [
      { type: "empty" },
      { type: "emailFormat" },
      { type: "maxLength", value: 128 }
    ]
  },
  {
    name: "name",
    validators: [
      { type: "empty" },
      { type: "minLength", value: 2 },
      { type: "maxLength", value: 255 }
    ]
  },
  {
    name: "subject",
    validators: [
      { type: "empty" },
      { type: "minLength", value: 2 },
      { type: "maxLength", value: 128 }
    ]
  },
  {
    name: "yourQuery",
    validators: [
      { type: "empty" },
      { type: "minLength", value: 2 },
      { type: "maxLength", value: 5000 }
    ]
  }
];

export default {
  name: "ReportUser",
  components: {
    SettingsContainer,
    InputWrapper,
    Spinner
  },
  props: {
    userToReport: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      isZendeskApiEnabled: zendeskEnabled(),
      requestSent: false,
      ...inputFields.reduce((hash, field) => {
        hash[field.name] = {
          touched: false,
          error: false,
          hint: "",
          value: ""
        };
        return hash;
      }, {}),
      response: null,
      loading: false,
      zendeskError: ""
    };
  },
  computed: {
    ...mapGetters("auth", ["isLogged"]),
    isSubjectFullyPrepopulated() {
      if (
        this.userToReport?.displayName &&
        this.subject.value.includes(this.userToReport.displayName)
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    this.prepopulateFields();
  },
  apollo: {
    profile: {
      query: GET_PROFILE_SUPPORT_FORM,
      update(data) {
        if (data.getMyProfile?.email) {
          this.emailAddress.value = data.getMyProfile.email;
          this.inputChanged("emailAddress");
        }
        if (data.getMyProfile?.displayName) {
          this.name.value = data.getMyProfile.displayName;
          this.inputChanged("name");
        }
      },
      fetchPolicy: "no-cache",
      variables() {
        return {
          screenName: this.$store.getters["auth/currentUser"]?.attributes
            .screenName
        };
      },
      skip() {
        return !this.isLogged;
      }
    }
  },
  methods: {
    prepopulateFields() {
      this.subject.value = this.userToReport?.displayName
        ? `Reporting: ${this.userToReport.displayName}`
        : "Reporting: ";
    },
    setError(fieldName, message, params) {
      this[fieldName].error = true;
      this[fieldName].hint = this.$t(message, params);
    },
    unsetError(fieldName) {
      this[fieldName].touched = true;
      this[fieldName].error = false;
      this[fieldName].hint = "";
    },
    inputChanged(fieldName) {
      validateField(
        this,
        inputFields.find(field => field.name === fieldName),
        this.setError,
        this.unsetError
      );
    },
    async submitTicket() {
      this.zendeskError = "";
      if (!validateObject(this, inputFields, this.setError, this.unsetError))
        return;
      this.loading = true;
      zendeskCreateRequest({
        name: this.name.value,
        email: this.emailAddress.value,
        subject: this.subject.value,
        comment: this.yourQuery.value,
        type: "incident",
        requestType: "Report User",
        requestContext: { profileId: this.userToReport.id }
      })
        .then(() => {
          this.requestSent = true;
        })
        .catch(err => {
          this.zendeskError = err.response?.data?.description || err.message;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
