<template>
  <RegisterContainer
    :header="$t('message.contact-preferences.permission-to-contact')"
    @next="next()"
  >
    <h5 class="mt-4 mb-1">
      {{ $t("message.contact-preferences.heading") }}
    </h5>
    <p class="font-body2 mb-7">
      {{ $t("message.contact-preferences.subtext-2") }}
    </p>
    <div class="option">
      <Checkbox
        id="team-updates"
        v-model="preferences"
        :trueValue="$system_config.teamUpdatesYes"
        :falseValue="$system_config.teamUpdatesNo"
      />
      <label class="font-body" for="team-updates">{{
        $t("message.contact-preferences.team-updates")
      }}</label>
    </div>
    <div class="option">
      <Checkbox
        id="recommended-content"
        v-model="preferences"
        :trueValue="$system_config.recommendedContentYes"
        :falseValue="$system_config.recommendedContentNo"
      />
      <label class="font-body" for="recommended-content">
        {{ $t("message.contact-preferences.recommended-content") }}
      </label>
    </div>
    <div class="option">
      <Checkbox
        id="news-and-offers"
        v-model="preferences"
        :trueValue="$system_config.marketingContentYes"
        :falseValue="$system_config.marketingContentNo"
      />
      <label class="font-body" for="news-and-offers">
        {{ $t("message.contact-preferences.marketing-content") }}
      </label>
    </div>
  </RegisterContainer>
</template>
<script>
import Checkbox from "@/components/form/Checkbox.vue";
import RegisterContainer from "@/components/register/RegisterContainer.vue";
import { loadContactPreferencesAttribute } from "@/utils/basicHelpers";
export default {
  name: "ContactPreferences",
  components: {
    Checkbox,
    RegisterContainer
  },
  data: () => {
    return {
      step: null,
      teamUpdates: false,
      newsAndOffers: false,
      recommendedContent: false,
      preferences: ""
    };
  },
  beforeMount() {
    this.step = this.$route.meta.step;
    if (
      this.$route.name != this.$store.getters["auth/getNewUserValidLocation"] &&
      this.$store.getters["auth/getNewUserStep"] < this.step - 1
    ) {
      this.$router.replace({
        name: this.$store.getters["auth/getNewUserValidLocation"]
      });
    }
    this.preferences = loadContactPreferencesAttribute(
      this.$store.getters["auth/getNewUserContactPreferences"]
    );
  },
  methods: {
    next() {
      this.$store.dispatch(
        "auth/setContactPreferences",
        this.preferences.join(",")
      );
      this.$store.dispatch("auth/setNewUserStepCompleted", this.step);
      this.$router.push({ name: "CreatePassword" });
    }
  }
};
</script>
<style lang="postcss" scoped>
.option {
  @apply flex flex-row mt-6 items-center;

  label {
    @apply ml-4;
  }
}
</style>
