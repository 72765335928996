<template>
  <div class="w-full" v-if="posts.posts && posts.posts.length > 0">
    <PostModal
      :post="activePost"
      v-model="showPostModal"
      @requestedPrevious="requestedPrevious"
      @requestedNext="requestedNext"
      :loading="loading"
    />
    <div class="grid grid-cols-2 mb-2 px-4 md:px-0">
      <h4 class="capitalize text-left">
        {{ title }}
      </h4>
      <template v-if="posts.nextToken || posts.posts.length > 4">
        <div class="invisible md:visible text-right">
          <slot name="seeAllPost" />
        </div>
      </template>
    </div>
    <div class="scrollbar-hidden w-full flex flex-row overflow-x-auto">
      <div
        v-for="(post, index) in posts.posts"
        :key="post.id"
        class="post flex flex-col w-auto"
        @click="displayPost(post, index)"
      >
        <div
          class="image bg-gray-200 relative h-post-height-small w-post-width-small md:w-post-width-large md:h-post-height-large"
        >
          <div
            v-if="post.creator.profileImageUrl"
            class="w-10 h-10 absolute left-2 bottom-2 rounded-full overflow-hidden z-10"
          >
            <img
              class="w-full h-full"
              :src="post.creator.profileImageUrl"
              :alt="post.creator.displayName + ' profile picture'"
            />
          </div>
          <PostRender :post="post" typeOfRender="list" />
          <template v-if="post.publishState !== 'published'">
            <div
              class="disabled-post-bg h-full w-full z-10 absolute top-0 left-0"
            ></div>
            <DisabledPostStatus :post="post" />
          </template>
        </div>
      </div>
      <template v-if="calculatedPlaceholderPosts > 0">
        <div
          class="post flex flex-col w-auto"
          v-for="i in calculatedPlaceholderPosts"
          :key="i"
        >
          <div
            class="h-post-height-small w-post-width-small md:w-post-width-large md:h-post-height-large bg-gray-200 rounded-sm overflow-hidden"
          />
        </div>
      </template>
      <div
        v-if="seeAllPostSlot && posts.nextToken"
        class="post w-auto md:hidden"
      >
        <div
          class="image bg-gray-200 h-post-height-small w-post-width-small h-post-height-small w-post-width-small md:w-post-width-large md:h-post-height-large relative flex justify-center items-center"
        >
          <slot name="seeAllPost" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PostModal from "@/components/posts/PostModal.vue";
import PostRender from "@/components/posts/PostRender.vue";
import DisabledPostStatus from "@/components/posts/DisabledPostStatus.vue";

export default {
  components: {
    PostRender,
    PostModal,
    DisabledPostStatus
  },
  props: {
    posts: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: "posts"
    }
  },
  data() {
    return {
      showPostModal: false,
      activePost: null,
      activePostIndex: null,
      postImg: "/test-imgs/effect.png",
      loading: false
    };
  },
  computed: {
    seeAllPostSlot() {
      return !!this.$slots.seeAllPost;
    },
    calculatedPlaceholderPosts() {
      const minPostsNeeded = this.isDesktop ? 4 : 2;
      if (minPostsNeeded > this.posts.posts.length) {
        return minPostsNeeded - this.posts.posts.length;
      }

      return 0;
    },
    isDesktop() {
      return this.$store.getters["general/isDesktop"];
    }
  },
  watch: {
    showPostModal(val) {
      this.$emit("postInProgress", val);
    },
    posts: {
      deep: true,
      handler() {
        this.loading = false;
        this.requestedNext();
      }
    }
  },
  methods: {
    displayPost(post, index) {
      this.activePostIndex = index;
      this.activePost = post;
      this.showPostModal = true;
    },
    requestedPrevious() {
      if (this.activePostIndex === 0) {
        this.showPostModal = false;
        this.activePostIndex = null;
      } else {
        this.activePostIndex--;
        this.activePost = this.posts.posts[this.activePostIndex];
      }
    },
    requestedNext() {
      if (this.loading) return;

      if (
        this.activePostIndex === this.posts.posts.length - 1 &&
        !this.posts.nextToken
      ) {
        this.showPostModal = false;
        this.activePostIndex = null;
      } else if (this.activePostIndex === this.posts.posts.length - 1) {
        this.loading = true;
        this.$emit("requestNextSet");
      } else {
        if (this.posts.posts[this.activePostIndex + 1]) {
          this.activePostIndex++;
          this.activePost = this.posts.posts[this.activePostIndex];
        }
      }
    }
  }
};
</script>
<style lang="postcss" scoped>
.font-special {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
}

.post .image {
  @apply rounded-sm;

  overflow: hidden;
  position: sticky;
}

.post {
  @apply mr-4 md:mr-6 md:hidden;
}

.post:first-child {
  @apply ml-4 md:ml-0;
}

.disabled-post {
  background-color: #0009;

  @apply text-white absolute text-center justify-center flex h-full w-full items-center top-0 left-0 px-2;
}

.post:nth-child(1) {
  @apply md:block;
}

.post:nth-child(2) {
  @apply md:block;
}

.post:nth-child(3) {
  @apply md:block;
}

.post:nth-child(4) {
  @apply md:block md:mr-0;
}
</style>
