<template>
  <div class="mx-auto flex flex-grow flex-col w-full">
    <div class="flex flex-grow flex-col text-center">
      <template v-if="!loading && confirmed">
        <div class="flex flex-grow flex-col justify-center">
          <LogoConfirmed />
          <h3 class="block">{{ $t("message.your-account-is-ready") }}</h3>
          <div class="font-body mt-4">
            <p>{{ $t("message.we-are-glad-youre-here") }}</p>
            <p>{{ $t("message.now-its-time-for-action") }}</p>
          </div>
        </div>
      </template>
      <template v-if="!loading && error">
        <div class="w-32 mx-auto mt-24 mb-10 relative">
          <img
            @click="$router.push({ name: 'CreatePassword' })"
            class="w-full"
            src="@/assets/img/icons/mail-gray.svg"
            alt="email"
          />
          <img
            class="w-8 absolute -right-4 -bottom-2"
            src="@/assets/img/icons/warning.svg"
            alt="warning"
          />
        </div>
        <div>
          <h5 class="text-black text-center px-8">
            {{ $t("message.link-expired-text") }}
          </h5>
        </div>
      </template>
    </div>
    <div class="flex items-end">
      <button
        v-if="!loading && confirmed"
        type="submit"
        class="mx-auto btn btn-lg btn-default mt-4 mb-8"
        @click="$router.push({ name: 'SignInPage' })"
      >
        <span class="mx-auto">
          {{ $t("message.done") }}
        </span>
      </button>
      <button
        v-if="!loading && error"
        @click="$router.push({ name: 'SignUpEmailForm' })"
        class="btn btn-lg btn-default my-4 mb-12"
      >
        <span class="mx-auto">
          {{ $t("message.sign-up") }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { Auth } from "@aws-amplify/auth";
import LogoConfirmed from "@/components/LogoConfirmed.vue";

export default {
  name: "ConfirmEmail",
  components: { LogoConfirmed },
  data: () => {
    return {
      loading: true,
      confirmed: false,
      error: false
    };
  },
  async mounted() {
    if (this.$route.query["confirm_code"] && this.$route.query["email"]) {
      const code = this.$route.query["confirm_code"];
      const email = this.$route.query["email"];
      const res = await this.ConfirmSignUp(code, email);
      if (res) {
        this.error = false;
        this.confirmed = true;
      } else {
        this.error = true;
      }
      this.loading = false;
    } else {
      this.error = true;
      this.loading = true;
    }
  },
  computed: {},
  methods: {
    async ConfirmSignUp(code, email) {
      try {
        await Auth.confirmSignUp(email.toLowerCase(), code);
        return true;
      } catch (err) {
        return false;
      }
    }
  }
};
</script>
