<template>
  <div
    class="badge h-7 overflow-hidden float-right m-2 bg-green-300 border-2 border-black rounded-full flex justify-center items-center"
  >
    <span v-if="badgeType === 'count'" class="text-xs font-bold px-1">
      {{ count }}
    </span>
    <img
      v-else
      src="@/assets/img/icons/check.svg"
      class="tick w-6 h-full"
      alt="check"
    />
  </div>
</template>

<script>
export default {
  props: {
    taskType: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    count: {
      type: Number,
      required: true
    }
  },
  computed: {
    badgeType() {
      if (this.taskType === "DonationTask") {
        return "count";
      } else {
        return "tick";
      }
    },
    isCompleted() {
      return this.status === "complete";
    }
  }
};
</script>

<style lang="postcss" scoped>
.badge {
  min-width: 28px;
}
.tick {
  padding: 5px;
}
</style>
