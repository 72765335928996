<template>
  <section class="section-hero grid h-5/6">
    <div class="container mx-auto p-4 md:mb-16">
      <a
        href="https://www.gameofourlives.org/"
        class="w-max bg-white rounded-full font-medium uppercase px-6 py-4 ml-auto mr-0 hidden md:block hover:bg-gray-100 hover:shadow-lg"
      >
        Game of our lives foundation
      </a>
      <div class="w-full md:w-max m-auto mt-6">
        <h1 class="">
          It’s not game over. <br />
          It’s game on!
        </h1>
        <h3 class="mt-4 md:mt-0">
          This is the Game of Our Lives
        </h3>
        <div v-if="!isLogged" class="mb-4 mt-4 md:mb-0">
          <button
            class="w-full md:w-56 border border-black rounded-full font-medium uppercase py-5 mb-4 md:mb-0 md:mr-4 hover:bg-blue-500 hover:shadow-lg"
            @click="$router.push({ name: 'SignInPage' })"
          >
            Log in
          </button>
          <button
            class="w-full md:w-56 bg-black text-white rounded-full font-medium uppercase py-5 hover:shadow-lg"
            @click="$router.push({ name: 'SignUpEmailForm' })"
          >
            Sign up
          </button>
        </div>
      </div>
    </div>
    <video
      id="bgvid"
      class="h-auto w-full object-contain md:object-fill order-first md:order-none mt-6 md:mt-0"
      playsinline
      autoplay
      muted
      loop
      poster="@/assets/img/home/logo.svg"
    >
      <source src="@/assets/video/hero-video.mp4" type="video/mp4" />
    </video>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HomeHero",
  computed: {
    ...mapGetters("auth", ["isLogged"])
  }
};
</script>

<style lang="postcss" scoped>
video::-internal-media-controls-overlay-cast-button {
  display: none;
}

.section-hero {
  background-color: #759fea;
}
</style>
