<template>
  <div id="post-image-creator-parent" class="relative">
    <BaseConfirmationModal
      v-model="showClosePostConfirmation"
      :confirmText="$t('message.discard')"
      :cancelText="$t('message.cancel')"
      @actionConfirmed="closePostCreatorConfirmed()"
    >
      <div class="flex flex-col text-center flex items-center justify-center">
        <template>
          <h4>{{ $t("message.discard-current-post") }}</h4>
        </template>
      </div>
    </BaseConfirmationModal>
    <div
      id="image-post-creator-render"
      class="h-full z-20 relative w-full flex flex-col items-center justify-center rounded-md overflow-hidden"
      :style="`background-color: ${selectedColor}`"
    >
      <div id="controls" class="absolute top-0 w-full z-10 px-6">
        <div
          id="controls-bg"
          class="controls-bg absolute top-0 left-0 w-full"
        />
        <div class="flex flex-row text-left w-full mt-6 items-center">
          <button v-show="!hideButton" class="w-10 flex justify-center">
            <img
              @click="closePostCreator()"
              src="@/assets/img/icons/close-arrow-white.svg"
              alt="close"
            />
          </button>
          <div class="flex flex-row flex-grow justify-end">
            <button
              v-show="!hideButton"
              id="post-upload-image-button"
              class="mr-9"
            >
              <img
                src="@/assets/img/icons/image-plus.svg"
                alt="close"
                class="icon-shadow"
              />
            </button>
            <button @click="initTextEdition">
              <h1 id="text-icon" class="text-white text-shadow">
                Aa
              </h1>
            </button>
          </div>
        </div>
        <ColorSelector
          v-if="!hideButton"
          v-model="selectedColor"
          :selected="selectedColor"
          class="mt-4"
        />
      </div>
      <div id="text-input-box" class="text-center p-2 relative">
        <p class="post-text-md">
          <span
            id="editableInput"
            v-if="allowTextEdition || postText != ''"
            placeholder="Input text"
            :style="postStyle"
            :contenteditable="allowTextEdition"
            @click="initTextEditionFromSpan"
            @keyup="updatePostText"
            @focus="log"
            @blur="log"
            ref="spanInput"
          />
        </p>
        <img
          v-if="allowTextEdition || postText != ''"
          src="@/assets/img/icons/move.svg"
          alt="move"
          id="move-icon"
          class="w-6 h-6 -top-2 -right-2  hidden md:block md:absolute"
        />
      </div>
      <PostImageUploader
        class="z-40"
        :cropperAspectRatio="9 / 16"
        :cropperSize="1"
        buttonId="post-upload-image-button"
        @uploadedImage="imageUploaded"
        @discardAndClose="closeImageUploader()"
      />
      <div
        id="post-image"
        v-show="postImage !== ''"
        class="w-full h-full z-1 bg-cover bg-center"
        :style="`background-Image: url(${postImage})`"
      />
      <div
        id="post-button"
        class="flex w-full h-32 absolute bottom-0 flex flex-col justify-center"
      >
        <ColorSelector
          v-if="allowTextEdition"
          class="horizontal justify-center w-full mb-4"
          v-model="selectedTextColor"
          :selected="selectedTextColor"
          :options="['red', 'black', 'white']"
        />
        <button
          v-if="(postText !== '' || postImage !== '') && !hideButton"
          @click.prevent="confirmPost()"
          class="base-shadow
            btn
            mx-auto
            bg-white
            text-black
            border
            border-white
            outline-none
            focus:outline-none
            flex
            text-center
            justify-center
            items-center
            px-6
            z-20"
        >
          <span class="mx-auto">
            {{ $t("message.post-now") }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ColorSelector from "@/components/shared/ColorSelector.vue";
import PostImageUploader from "@/components/shared/PostImageUploader.vue";
var box;
var diffX;
var diffY;
function touchmove(e) {
  var touchLocation = e.targetTouches[0];
  const newXPoss = touchLocation.pageX - diffX;
  const newYPoss = touchLocation.pageY - diffY;
  box.style.left = newXPoss + "px";
  box.style.top = newYPoss + "px";
}
function touchstart(e) {
  var touchLocation = e.targetTouches[0];
  diffX = touchLocation.pageX - box.offsetLeft;
  diffY = touchLocation.pageY - box.offsetTop;
}
function dragstart(e) {
  diffX = e.x - box.offsetLeft;
  diffY = e.y - box.offsetTop;
}
function drag(e) {
  const newXPoss = e.x - diffX;
  const newYPoss = e.y - diffY;
  box.style.left = newXPoss + "px";
  box.style.top = newYPoss + "px";
}
function dragover(e) {
  e.preventDefault();
}
function activateDragable(elementId) {
  box = document.getElementById(elementId);
  diffX = box.offsetLeft;
  diffY = box.offsetLeft;
  box.addEventListener("touchstart", touchstart);
  box.addEventListener("touchmove", touchmove);
  box.addEventListener("dragstart", dragstart);
  box.addEventListener("drag", drag);
  document.addEventListener("dragover", dragover);
}
function removeEventListener(elementId) {
  var box = document.getElementById(elementId);
  if (box) {
    box.removeEventListener("touchmove", touchmove);
    box.removeEventListener("touchstart", touchstart);
    box.removeEventListener("dragstart", dragstart);
    box.removeEventListener("drag", drag);
    box.removeEventListener("dragover", dragover);
  }
}

export default {
  components: {
    ColorSelector,
    PostImageUploader
  },
  data() {
    return {
      postText: "",
      selectedColor: "#F93A5B",
      selectedTextColor: "white",
      allowTextEdition: false,
      postImage: "",
      showClosePostConfirmation: false,
      hideButton: false
    };
  },
  computed: {
    postStyle() {
      if (this.postText == "") return "";

      let style = this.selectedTextColor
        ? `color: ${this.selectedTextColor};`
        : "";
      style =
        style +
        (this.selectedTextColor && this.selectedTextColor === "white"
          ? "background-Color: #000000A3;"
          : "background-color: #ffffff9e;");
      return style;
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.postText = "";
      }
    }
  },
  mounted() {
    activateDragable("text-input-box");
  },
  beforeDestroy() {
    removeEventListener("text-input-box");
  },
  methods: {
    log() {
      this.hideButton = document.activeElement.id === "editableInput";
    },
    updatePostText() {
      const element = this.$refs.spanInput;
      this.postText = element.innerText;
    },
    textAreaAdjust() {
      this.$refs.textarea.style.height = "1px";
      this.$refs.textarea.style.height =
        25 + this.$refs.textarea.scrollHeight + "px";
    },
    closePostCreator() {
      this.showClosePostConfirmation = true;
    },
    closePostCreatorConfirmed() {
      this.$emit("discardAndClose");
    },
    confirmPost() {
      const newPost = {
        backgroundColor: this.selectedColor
      };
      if (this.postText !== "") {
        newPost.text = this.postText;
        newPost.textColor = this.selectedTextColor;
      }
      if (this.postImage !== "") {
        newPost.imageUrl = this.postImage;
      }
      var renderBox = document.getElementById("image-post-creator-render");
      var editableInput = document.getElementById("text-input-box");
      const percentageFromLeft =
        (editableInput.offsetLeft * 100) / renderBox.offsetWidth;
      const percentageFromTop =
        (editableInput.offsetTop * 100) / renderBox.offsetHeight;
      newPost.textPlacement = {
        angle: 0,
        x: percentageFromLeft.toFixed(2),
        y: percentageFromTop.toFixed(2)
      };
      this.$emit("postCreated", newPost);
    },
    imageUploaded(image) {
      this.postImage = image.imageUrl;
    },
    initTextEdition() {
      this.allowTextEdition = !this.allowTextEdition;
      if (this.allowTextEdition) {
        this.$nextTick(() => {
          const input = document.getElementById("editableInput");
          input.focus();
        });
      }
    },
    initTextEditionFromSpan() {
      if (
        document.activeElement.id !== "editableInput" &&
        !this.allowTextEdition
      ) {
        this.initTextEdition();
      }
    }
  }
};
</script>
<style lang="postcss" scoped>
#move-icon {
  cursor: grab;
}

#move-icon:active {
  cursor: grabbing;
}

#post-image-creator-parent {
  position: fixed;
  z-index: 20;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #000000b5;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 600px) {
  #image-post-creator-render {
    max-width: var(--desktop-post-width);
    max-height: var(--desktop-post-height);
  }
}

.modal-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  animation: fadeIn 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

textarea {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  resize: none;
}

textarea::-webkit-scrollbar {
  display: none;
}

#controls-bg {
  height: 300px;
  z-index: -1;
  background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
  opacity: 0.4;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

span {
  padding: 0 5px;
  border-radius: 5px;
  box-decoration-break: clone;
  line-height: 45px;
  max-width: 300px;
  text-align: center;
}

p {
  margin: auto;
  display: contents;
}

.post-text-md {
  font-size: 30px;
}

[contenteditable="true"]:empty::before {
  content: attr(placeholder);
  pointer-events: none;
  background-color: transparent !important;
  width: 100%;
  color: #fff;
  opacity: 0.5;
}

span:focus {
  outline: none;
}

#text-input-box {
  position: absolute;
  z-index: 40;
}
</style>
