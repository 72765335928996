<template>
  <nav class="menu bg-white">
    <ul class="font-body2">
      <li class="border-b border-gray-200 border-solid pb-3 mb-5">
        <div v-if="postToTeam">{{ $t("message.post-to-team") }}</div>
        <div v-else class="flex flex-row items-center">
          <img
            v-if="user.attributes.profileImageUrl"
            class="w-10 h-10 mr-3 rounded-full"
            :src="user.attributes.profileImageUrl"
            alt="user avatar"
          />
          <div v-else class="w-10 h-10 mr-3 rounded-full bg-gray-200" />
          <div id="profile-name" class="capitalize">
            {{ user.attributes.displayName }}
          </div>
        </div>
      </li>
      <li class="mb-3">
        <button @click="$emit('selectedType', 'video')">
          🎥 {{ $t("message.post-a-video") }}
        </button>
      </li>
      <li>
        <button @click="$emit('selectedType', 'image-text')">
          📷 {{ $t("message.post-image-text") }}
        </button>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: "NewPostMenu",
  props: {
    user: {
      type: Object,
      required: true
    },
    postToTeam: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      top: 0,
      left: 0
    };
  },
  beforeMount() {
    const button = document.querySelector(".plus-icon");
    this.top = button.offsetTop;
    this.left = button.offsetLeft;
  },
  mounted() {
    const menu = document.querySelector(".menu");
    menu.style.top = this.top - menu.offsetHeight - 15 + "px";
    menu.style.left = this.left - menu.offsetWidth + 50 + "px";
  }
};
</script>
<style lang="postcss" scoped>
.menu {
  position: fixed;
  z-index: 20;
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
}

#profile-name {
  max-width: 180px;
}
</style>
