<template>
  <InfiniteScroll
    :loading="loading"
    @loadMore="loadMore"
    :hasMoreToLoad="hasMoreToLoad"
  >
    <div
      class="container mx-auto relative w-full flex flex-row mt-6 h-11 mb-6 max-w-desktop"
    >
      <BackButton
        type="dark"
        @click="viewProfile($route.params.profileId)"
        class="absolute left-4 top-0"
      />
      <div class="flex flex-grow justify-center">
        <h3 class="capitalize">
          {{ title }}
        </h3>
      </div>
    </div>
    <template v-if="profilesList && profilesList.length > 0">
      <div
        id="follower-list"
        class="grid grid-col-1 gap-4 scrollbar-hidden w-full overflow-x-auto mb-4 px-4"
        :class="{ 'bg-white border-solid rounded-md px-14 py-12': isDesktop }"
      >
        <div
          v-for="(profile, index) in profilesList"
          :key="index"
          class="flex flex-row w-full items-center"
        >
          <Avatar
            avatarType="small"
            :displayName="profile.displayName"
            :profileImageUrl="profile.profileImageUrl"
            @click="viewProfile(profile.screenName)"
          />
          <div
            class="flex-grow flex flex-col pl-2"
            @click="viewProfile(profile.id)"
          >
            <p class="text-sm sm:text-base">
              {{ profile.displayName }}
            </p>
            <p class="text-xs sm:text-sm">
              {{ profile.screenName }}
            </p>
          </div>
          <template v-if="!isLoggedUser(profile.id)">
            <button
              @click="follow(profile)"
              v-if="!profile.following"
              class="btn-follow btn w-24 md:w-28 h-10 bg-blue-900 text-white border border-blue-900 outline-none focus:outline-none flex text-center justify-center items-center break-normal"
            >
              {{ $t("message.follow") }}
            </button>
            <button
              @click="unfollow(profile)"
              v-else
              class="btn-follow btn w-24 md:w-28 h-10 text-blue-900 border border-blue-900 outline-none focus:outline-none flex text-center justify-center items-center break-normal"
            >
              <template v-if="$apollo.loading">
                {{ $t("message.loading") }}
              </template>
              <template v-else>
                {{ $t("message.following") }}
              </template>
            </button>
          </template>
        </div>
      </div>
    </template>
    <template v-else-if="loading">
      <FollowersPageSkeleton />
    </template>
    <template v-else>
      <NotFound :title="noFollowersText" :showLogo="false" />
    </template>
  </InfiniteScroll>
</template>

<script>
import Avatar from "@/components/shared/Avatar.vue";
import BackButton from "@/components/shared/BackButton.vue";
import FollowersPageSkeleton from "@/components/skeletons/FollowersPageSkeleton.vue";
import NotFound from "@/components/shared/NotFound.vue";
import InfiniteScroll from "@/components/shared/InfiniteScroll.vue";
import apolloErrorHandling from "@/utils/apolloErrorHandling.js";
import {
  GET_PROFILE_FOLLOWERS,
  GET_PROFILE_FOLLOWING,
  GET_OTHER_PROFILE
} from "@/graphql/queries/user/userQueries.js";
import {
  FOLLOW_PROFILE,
  UNFOLLOW_PROFILE
} from "@/graphql/queries/user/userMutations.js";
import { mapGetters } from "vuex";
export default {
  name: "ProfileFollowersPage",
  components: {
    Avatar,
    BackButton,
    FollowersPageSkeleton,
    NotFound,
    InfiniteScroll
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    view() {
      return this.$route.meta.view;
    },
    isViewFollowers() {
      return this.view === "followers";
    },
    isViewFollowing() {
      return this.view === "following";
    },
    title() {
      return this.isViewFollowers
        ? this.$t("message.followers")
        : this.$t("message.following");
    },
    noFollowersText() {
      return this.isViewFollowers
        ? this.$t("message.no-followers-yet")
        : this.$t("message.not-following-anyone-yet");
    },
    profiles() {
      if (this.isViewFollowers) {
        return this.getFollowers;
      } else {
        return this.getFollowing;
      }
    },
    hasMoreToLoad() {
      return this.profiles?.nextToken ? true : false;
    },
    loadLimit() {
      return this.isDesktop ? 8 : 4;
    },
    isDesktop() {
      return window.innerWidth > 768;
    }
  },
  data() {
    return {
      profilesList: [],
      profileId: null,
      loading: true,
      nextToken: null
    };
  },
  apollo: {
    getProfile: {
      query: GET_OTHER_PROFILE,
      update(data) {
        this.profileId = data.getProfile.id;
      },
      variables() {
        return {
          screenName: this.$route.params.screenName
        };
      },
      skip() {
        return this.profileId;
      },
      fetchPolicy: "no-cache"
    },
    getFollowers: {
      query: GET_PROFILE_FOLLOWERS,
      update(data) {
        this.profilesList.push(...data.getProfileFollowers.profiles);
        this.loading = false;
        return data.getProfileFollowers;
      },
      variables() {
        return {
          limit: this.loadLimit,
          profileId: this.profileId,
          nextToken: this.nextToken
        };
      },
      skip() {
        return !this.profileId || !this.isViewFollowers;
      },
      fetchPolicy: "no-cache"
    },
    getFollowing: {
      query: GET_PROFILE_FOLLOWING,
      update(data) {
        this.profilesList.push(...data.getProfileFollowing.profiles);
        this.loading = false;
        return data.getProfileFollowing;
      },
      variables() {
        return {
          limit: this.loadLimit,
          profileId: this.profileId,
          nextToken: this.nextToken
        };
      },
      skip() {
        return !this.profileId || !this.isViewFollowing;
      },
      fetchPolicy: "no-cache"
    }
  },
  methods: {
    loadMore() {
      if (!this.loading) {
        this.loading = true;
        this.nextToken = this.profiles.nextToken;
      }
    },
    isLoggedUser(id) {
      if (this.currentUser?.id == id) {
        return true;
      } else {
        return false;
      }
    },
    viewProfile(profileId) {
      this.$router.push({
        name: "ProfilePage",
        params: { profileId }
      });
    },
    follow(profile) {
      this.$apollo
        .mutate({
          mutation: FOLLOW_PROFILE,
          variables: {
            profileId: profile.id
          },
          update: (data, data2) => {
            if (data2.data.followProfile == true) {
              profile.following = true;
            }
          }
        })
        .catch(err => {
          apolloErrorHandling(err);
        });
    },
    unfollow(profile) {
      this.$apollo
        .mutate({
          mutation: UNFOLLOW_PROFILE,
          variables: {
            profileId: profile.id
          },
          update: (data, data2) => {
            if (data2.data.unfollowProfile == true) {
              profile.following = false;
            }
          }
        })
        .catch(err => {
          apolloErrorHandling(err);
        });
    }
  }
};
</script>

<style lang="postcss" scoped>
.btn-follow {
  font-size: var(--button-font-size-xs);
  border-radius: 80px;
  height: 34px;
}

#follower-list > div:not(:last-child) {
  border-bottom: 1px solid #f0f0e8;

  @apply pb-4;
}

@media screen and (min-width: 640px) {
  .btn-follow {
    font-size: var(--button-font-size-sm);
    height: 38px;
  }
}
</style>
