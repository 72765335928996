<template>
  <div
    :class="
      `badge
      flex
      justify-center
      items-center
      text-center
      shape-${shape}
      absolute
      position-${position}`
    "
    :style="{
      color: textColor,
      backgroundColor: backgroundColor,
      borderColor: borderColor
    }"
  >
    {{ text }}
  </div>
</template>
<script>
export default {
  name: "badge",
  props: {
    textColor: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    },
    borderColor: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    shape: {
      type: String,
      required: true
    },
    position: {
      type: String,
      required: true
    }
  }
};
</script>
<style lang="postcss" scoped>
.shape-button {
  padding: 8px 12px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 80px;
  border: 2px solid;
  letter-spacing: 0.5px;
  @media screen and (max-width: 600px) {
    font-size: 11px;
    border-radius: 80px;
  }

  &.position-top_left {
    top: 16px;
    left: 16px;
  }
  &.position-top_right {
    top: 16px;
    right: 16px;
  }
  &.position-bottom_right {
    bottom: 16px;
    right: 16px;
  }
  &.position-bottom_left {
    bottom: 16px;
    left: 16px;
  }
}
.shape-circle {
  border-radius: 50%;
  padding: 10px;
  height: 184px;
  width: 184px;
  font-weight: 700;
  font-size: 24px;
  border: 1px solid;
  @media screen and (max-width: 600px) {
    height: 82px;
    width: 82px;
    font-weight: 700;
    font-size: 11px;
  }

  &.position-top_left {
    top: 30px;
    left: 20px;
  }
  &.position-top_right {
    top: 30px;
    right: 20px;
  }
  &.position-bottom_right {
    bottom: 30px;
    right: 20px;
  }
  &.position-bottom_left {
    bottom: 30px;
    left: 20px;
  }
}
</style>
