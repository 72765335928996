<template>
  <div class="mx-auto flex flex-grow flex-col w-full">
    <h2 class="capitalize mx-auto flex justify-center">
      {{ $t("message.signup-link-expired") }}
    </h2>
    <router-view />
    <div class="flex flex-grow flex-col">
      <div class="w-32 mx-auto mt-24 mb-10 relative">
        <img
          @click="$router.push({ name: 'CreatePassword' })"
          class="w-full"
          src="@/assets/img/icons/mail-gray.svg"
          alt="email"
        />
        <img
          class="w-8 absolute -right-4 -bottom-2"
          src="@/assets/img/icons/warning.svg"
          alt="warning"
        />
      </div>
      <div>
        <h5 class="text-black text-center px-8">
          {{ $t("message.link-expired-text") }}
        </h5>
      </div>
    </div>
    <div class="flex items-end">
      <button
        @click="$router.push({ name: 'SignUpEmailForm' })"
        class="btn btn-lg btn-default mt-4 mb-12 mx-auto"
      >
        <span class="mx-auto">
          {{ $t("message.sign-up") }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NameForm",
  components: {},
  data: () => {
    return {
      firstName: "",
      lastName: "",
      error: ""
    };
  },
  computed: {},
  methods: {}
};
</script>
