<template>
  <div>
    <div class="grid grid-cols-2 mb-2 px-4 md:px-0">
      <h4 class="capitalize text-left">
        <slot name="title"></slot>
      </h4>
    </div>
    <div
      id="task-list"
      class="scrollbar-hidden w-full flex flex-row overflow-x-auto mt-4"
    >
      <template v-for="(taskSet, index) in splittedTasks">
        <div class="tasks-set mr-4 flex flex-col w-2/4" :key="index">
          <router-link
            v-for="task in taskSet"
            :key="task.id"
            class="task flex flex-col w-full justify-start mb-4 bg-white border border-gray-100 rounded-sm p-4 cursor-pointer"
            :to="{
              name: 'TeamPage',
              params: { slug: task.team.slug }
            }"
          >
            <div class="task-info-grid">
              <div
                class="image rounded-sm h-20 w-20 relative bg-no-repeat bg-center bg-cover overflow-hidden"
                :style="`background-image: url('${task.coverImage.url}')`"
              />
              <div class="info">
                <div id="task-title" class="text-xs font-bold">
                  {{ task.coverHeading }}
                </div>
                <p id="task-description" class="text-black">
                  {{ task.coverContent }}
                </p>
              </div>
            </div>
            <h3 class="w-full text-blue-900 text-xs mt-2">
              {{ $t("message.team") }}: {{ task.team.name }}
            </h3>
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tasks: {
      type: Array,
      required: true
    }
  },
  computed: {
    splittedTasks() {
      if (this.tasks.length == 2) {
        return [[this.tasks[0]], [this.tasks[1]]];
      }
      let divided = this.tasks.reduce((a, b) => {
        let res = [...a];
        if (a.length > 0) {
          if (res[res.length - 1].length > 1) {
            res.push([b]);
          } else {
            res[res.length - 1] = [...res[res.length - 1], b];
          }
        } else {
          res.push([b]);
        }
        return res;
      }, []);
      return divided;
    }
  }
};
</script>
<style lang="postcss" scoped>
.tasks-set {
  min-width: 279px;
}

.tasks-set:last-child {
  @apply md:mr-0;
}

.tasks-set:first-child {
  @apply ml-4 md:ml-0;
}

.bg-shadow {
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 0%,
    rgb(0 0 0 / 94%) 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  height: 71px;
  width: 100%;
}

#task-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box; /* stylelint-disable-line */
  display: -webkit-box; /* stylelint-disable-line */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

#task-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box; /* stylelint-disable-line */
  display: -webkit-box; /* stylelint-disable-line */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
}

.task-info-grid {
  display: grid;
  grid-template-columns: 90px 1fr;
}
</style>
