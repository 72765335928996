<template>
  <div
    class="h-full w-full absolute md:relative z-50 overflow-hidden flex justify-center items-center"
  >
    <PostWrapper
      @close="closeOrReturn"
      id="post"
      v-if="post"
      :post="post"
      :showPostControls="true"
    />
    <template v-if="!post && error">
      Post not found.
    </template>
  </div>
</template>
<script>
import { GET_SINGLE_POST } from "@/graphql/queries/post/postsQueries.js";
import PostWrapper from "@/components/posts/PostWrapper.vue";

export default {
  name: "PostPage",
  components: {
    PostWrapper
  },
  data() {
    return {
      error: null,
      showShareModal: false
    };
  },
  mounted() {
    console.log(this.$route);
  },
  apollo: {
    post: {
      query: GET_SINGLE_POST,
      update(data) {
        if (!data.getProfilePost) {
          this.error = true;
        }
        return data.getPost;
      },
      variables() {
        return {
          postId: this.$route.params.id
        };
      },
      fetchPolicy: "no-cache"
    }
  },
  methods: {
    share() {
      this.showShareModal = true;
    },
    closeOrReturn() {
      if (this.$router.referrer.name == null) {
        this.$router.push({ name: "HomeIn" });
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>
<style lang="postcss" scoped>
#layout-content {
  height: 100%;
}

#post {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 600px) {
  #post {
    width: var(--desktop-post-width);
    height: var(--desktop-post-height);
  }
}
</style>
