<template>
  <div class="h-full">
    <template v-if="team">
      <div class="head mb-8 md:mt-8 md:rounded-md md:overflow-hidden relative">
        <div id="main-image" class="w-full relative">
          <VideoRender
            :videoUrl="videoUrl"
            :captions="videoCaptions"
            :coverImgUrl="team.videoThumbnail.url || team.image.url"
          >
            <div class="bg-controls"></div>
            <BackButton
              @click="$router.push({ name: 'HomeIn' })"
              class="absolute left-4 top-12"
            />
            <Avatar
              v-if="currentUser"
              :displayName="currentUser.attributes.displayName"
              :profileImageUrl="currentUser.attributes.profileImageUrl"
              @click="
                $router
                  .push({
                    name: 'MyProfilePage'
                  })
                  .catch(() => {})
              "
              class="visible md:invisible absolute right-4 top-12 border border-white"
            />
          </VideoRender>
        </div>
        <div
          id="main-info"
          class="bg-gray-50 pt-4 relative flex flex-col md:bg-white md:pt-0 md:px-12 mx-auto"
        >
          <div class="flex flex-col container md:px-0">
            <h2
              class="whitespace-pre-line mb-4 text-black text-center md:text-left"
              v-html="team.title"
            ></h2>
            <button
              v-if="team.mainCaptain"
              class="bg-gray-200 hover:bg-gray-300 hover:shadow-sm rounded-lg w-max mx-auto md:mx-0"
              @click="
                $router.push({
                  name: 'CaptainProfilePage',
                  params: { profileSlug: team.mainCaptain.slug }
                })
              "
            >
              <img
                :src="team.mainCaptain.avatar.url"
                alt=""
                class="h-10 w-10 inline rounded-lg"
              />
              <span class="text-sm font-medium pl-4 pr-5">
                Captain: {{ team.mainCaptain.name }}
              </span>
            </button>

            <div
              class="font-body2 my-4 text-center md:text-left kentico-content"
              v-html="team.description.html"
            ></div>
            <div
              :class="{
                'bg-pink-400 p-4 text-white text-center rounded-md': isPreregister
              }"
            >
              <div v-if="isPreregister">
                <label for="preregister-text" class="block text-base mb-2"
                  >Pre-register</label
                >
                <p name="preregister-text" class="text-sm">
                  We are getting our tasks ready for {{ team.title }}. Want to
                  join already? Join now and you’ll be the first to know once
                  we’re live.
                </p>
              </div>

              <div
                class="w-full flex flex-col items-center"
                :class="isPreregister ? 'mt-4' : 'my-4'"
              >
                <button
                  @click="join()"
                  v-if="!team.member"
                  class="btn btn-lg px-6"
                  :class="isPreregister ? 'btn-clear-pink' : 'btn-default'"
                >
                  <span class="mx-auto" v-if="!joinLoading">
                    {{ $t("message.join-team") }}
                  </span>
                  <div class="loading w-6 h-6" v-else>
                    <Spinner class="sm-button" />
                  </div>
                </button>
                <button
                  @click="leave()"
                  v-else
                  class="btn btn-lg px-6"
                  :class="isPreregister ? 'btn-clear-pink' : 'btn-clear'"
                >
                  <span class="mx-auto" v-if="!joinLoading">
                    {{ $t("message.joined") }}
                  </span>
                  <div class="loading w-6 h-6" v-else>
                    <Spinner class="sm-button-dark" />
                  </div>
                </button>
                <router-link
                  class="teammates-link mx-auto my-2 text-blue-900"
                  :to="{ name: 'TeamMembersPage', slug: team.slug }"
                  >Meet your teammates</router-link
                >
              </div>
            </div>
            <div v-if="!isPreregister" class="grid grid-cols-2 gap-2">
              <MetricsBox
                iconPath="assets/img/icons/time.svg"
                :label="$t('message.minutes-given')"
                :value="Number(team.timeGiven)"
              />
              <MetricsBox
                iconPath="assets/img/icons/heart.svg"
                :label="$t('message.lives-impacted')"
                :showInfoIcon="true"
                :value="Number(pointsAwarded)"
              />
            </div>
          </div>
        </div>
      </div>
      <TasksList
        :tasks="team.mergedTasks"
        class="mb-7"
        v-if="team.mergedTasks.length > 0"
      >
        <template v-slot:title>
          {{ $t("message.give-time") }}
        </template>
        <template v-slot:seeAll>
          <router-link
            :to="{ name: 'TeamTasks', params: { slug: team.slug } }"
            v-if="
              appsyncTeam.visibleTasks.nextToken ||
                appsyncTeam.visibleTasks.tasks.length > 8
            "
          >
            {{ $t("message.see-all") }}
          </router-link>
        </template>
      </TasksList>
      <ProfileList
        v-if="cocaptains.length > 0"
        class="mb-7 md:my-0 md:bg-white md:p-6 md:rounded-md md:border md:border-gray-200"
        :profiles="cocaptains"
        profilesType="captain"
      >
        <template v-slot:title>
          {{ $t("message.cocaptains") }}
        </template>
      </ProfileList>
      <ProfileList
        v-if="supporters.length > 0"
        class="mb-7 md:my-4 md:bg-white md:p-6 md:rounded-md md:border md:border-gray-200"
        :profiles="supporters"
        profilesType="supporter"
      >
        <template v-slot:title>
          {{ $t("message.supporters") }}
        </template>
      </ProfileList>
      <SocialShareGeneric
        class="my-8"
        :url="teamUrl"
        :shareText="teamShareTitle | htmlToPlain"
        :data="shareData"
        :hashtags="teamShareHashtags"
        type="team"
      />
    </template>
    <template
      v-else-if="(kenticoLoading || appsyncLoading) && !errorLoadingTeam"
    >
      <TeamPageSkeleton />
    </template>
    <template v-else>
      <div class="w-full h-full flex flex-col relative  md:pt-6">
        <NotFound
          :title="$t('message.team-not-found')"
          :description="$t('message.team-not-found-description')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { KENTICO_GET_TEAM_AND_TASKS } from "@/kentico-api/teams.js";
import { GET_TEAM_QUERY } from "@/graphql/queries/user/userQueries.js";
import { GET_TASK_TEAM_SHARED_KENTICO } from "@/kentico-api/tasks.js";
import { JOIN_TEAM, LEAVE_TEAM } from "@/graphql/queries/user/userMutations.js";
import apolloErrorHandling from "@/utils/apolloErrorHandling.js";
import { seoMetadata } from "@/utils/seoMetadata";
import TeamPageSkeleton from "@/components/skeletons/TeamPageSkeleton.vue";
import NotFound from "@/components/shared/NotFound";
import BackButton from "@/components/shared/BackButton.vue";
import Spinner from "@/components/shared/Spinner.vue";
import VideoRender from "@/components/shared/VideoRender.vue";
import MetricsBox from "@/components/shared/MetricsBox.vue";
import TasksList from "@/components/shared/TasksList.vue";
import ProfileList from "@/components/team/ProfileList.vue";
import Avatar from "@/components/shared/Avatar.vue";
import SocialShareGeneric from "@/components/posts/SocialShareGeneric.vue";
import { mapGetters } from "vuex";
import { standardizeAppsyncPoints } from "@/utils/basicHelpers.js";

export default {
  name: "TeamPage",
  components: {
    TeamPageSkeleton,
    NotFound,
    BackButton,
    Spinner,
    VideoRender,
    MetricsBox,
    TasksList,
    ProfileList,
    Avatar,
    SocialShareGeneric
  },
  data() {
    return {
      kenticoLoading: true,
      appsyncLoading: true,
      joinLoading: false,
      showShareMenu: false,
      kenticoTeam: null,
      metaData: {
        metaDescription: null,
        metaTitle: null,
        metaImageUrl: null,
        metaVideoUrl: null,
        keywords: null
      },
      teamShareTitle: "",
      teamShareHashtags: "",
      errorLoadingTeam: false
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    pointsAwarded() {
      return (
        (this.kenticoTeam.pointsAwardedModifier || 0) +
        standardizeAppsyncPoints(this.team.pointsAwarded)
      );
    },
    team() {
      if (!this.appsyncTeam || !this.kenticoTeam) return null;

      // kenticoTeam.tasks.items lists tasks in the order we want to display them
      const mergedTasks = this.kenticoTeam.tasks.items.flatMap(kenticoTask => {
        // find an appsync match
        const appsyncTask = this.appsyncTeam.visibleTasks.tasks.find(
          appsyncTask => {
            return (
              appsyncTask.kenticoCodename === kenticoTask._system_.codename
            );
          }
        );
        if (appsyncTask) {
          return {
            ...kenticoTask,
            id: appsyncTask.id,
            pointsOnCompletion: appsyncTask.pointsOnCompletion,
            progress: appsyncTask.progress,
            taskType: appsyncTask.taskType,
            taskCompletionsCount: appsyncTask.taskCompletionsCount
          };
        }
        // if no results found return empty array, so flatmap can ignore this record
        return [];
      });

      return {
        ...this.appsyncTeam,
        ...this.kenticoTeam,
        mergedTasks
      };
    },
    teamUrl() {
      let props = this.$router.resolve({
        name: "TeamPage",
        params: { slug: this.team.slug }
      });
      return process.env.VUE_APP_WEB_URL + props.href;
    },
    videoUrl() {
      return this.team.video?.url || "";
    },
    isPreregister() {
      if (
        this.team.status.items.length > 0 &&
        this.team.status.items[0]._system_.codename === "pre_register"
      ) {
        return true;
      }
      return false;
    },
    videoCaptions() {
      return this.team.videoCaptions.items.map(caption => {
        return {
          url: caption.file.url,
          lang: caption.lang,
          label: caption.label
        };
      });
    },
    cocaptains() {
      return this.team.captains.items;
    },
    supporters() {
      return this.team.supporters.items;
    },
    shareData() {
      return {
        team: {
          id: this.team.id,
          slug: this.team.slug
        }
      };
    }
  },
  apollo: {
    appsyncTeam: {
      query: GET_TEAM_QUERY,
      update(data) {
        let team;
        if (data.getTeam) {
          team = data.getTeam;
          if (this.$route.query.sharedFromTask) {
            this.$apollo
              .query({
                client: "kenticoClient",
                query: GET_TASK_TEAM_SHARED_KENTICO,
                variables: {
                  codename: this.$route.query.sharedFromTask
                },
                fetchPolicy: "no-cache"
              })
              .then(response => {
                if (!response.data.task) return;
                const og = response.data.task;
                this.metaData.metaTitle =
                  og.socialShareTitle !== "" ? og.socialShareTitle : "";
                this.metaData.metaDescription =
                  og.socialShareDescription !== ""
                    ? og.socialShareDescription
                    : "";
                this.metaData.metaImageUrl =
                  og.socialShareImage?.url && og.socialShareImage?.url !== ""
                    ? og.socialShareImage.url
                    : "";
                this.$emit("updateHead");
              });
          }

          this.getKenticoTeamAndTasks(team.kenticoCodename);
        } else {
          this.errorLoadingTeam = true;
        }
        this.appsyncLoading = false;
        return team;
      },
      variables() {
        return {
          slug: this.$route.params.slug
        };
      },
      // `cache-and-network` results in the `update` method being called twice
      // `no-cache` results in the `update` method being called once
      fetchPolicy: "no-cache"
    }
  },
  methods: {
    getKenticoTeamAndTasks(teamCodename) {
      this.$apollo
        .query({
          client: "kenticoClient",
          query: KENTICO_GET_TEAM_AND_TASKS,
          variables: {
            teamCodename: teamCodename
          },
          fetchPolicy: "no-cache"
        })
        .then(response => {
          if (response.data.team) {
            this.kenticoTeam = response.data.team;
            this.teamShareTitle =
              response.data.team.teamShareTitle !== ""
                ? response.data.team.teamShareTitle
                : "";

            if (response.data.team.teamShareHashtags !== "") {
              this.teamShareHashtags = response.data.team.teamShareHashtags;
            }
            // openGraph
            if (!this.$route.query.sharedFromTask) {
              const og = response.data.team._openGraph;
              this.metaData.metaTitle =
                og.ogTitle !== "" ? og.ogTitle : response.data.team.title;
              this.metaData.metaDescription =
                og.ogDescription !== ""
                  ? og.ogDescription
                  : response.data.team.description?.html;
              this.metaData.metaImageUrl =
                og.ogImage?.url && og.ogImage?.url !== ""
                  ? og.ogImage.url
                  : response.data.team.image?.url;
              this.metaData.metaVideoUrl =
                og.ogVideo !== "" ? og.ogVideo : response.data.team.video?.url;
              this.$emit("updateHead");
            }
          } else {
            this.errorLoadingTeam = true;
          }
          this.kenticoLoading = false;
        });
    },
    join() {
      this.joinLoading = true;
      this.$apollo
        .mutate({
          mutation: JOIN_TEAM,
          variables: {
            teamId: this.team.id
          },
          update: (data, data2) => {
            if (data2.data.joinTeam == true) {
              this.team.membersCount = this.team.membersCount + 1;
              this.team.member = true;
              this.joinLoading = false;
              this.$analytics.joinTeam(this.team);
            }
          }
        })
        .catch(({ graphQLErrors }) => {
          apolloErrorHandling(graphQLErrors);
          this.joinLoading = false;
        });
    },
    leave() {
      this.joinLoading = true;
      this.$apollo
        .mutate({
          mutation: LEAVE_TEAM,
          variables: {
            teamId: this.team.id
          },
          update: (data, data2) => {
            if (data2.data.leaveTeam == true) {
              this.team.membersCount = this.team.membersCount - 1;
              this.team.member = false;
              this.joinLoading = false;
              this.$analytics.leaveTeam(this.team);
            }
          }
        })
        .catch(err => {
          console.error(err);
          this.joinLoading = false;
        });
    }
  },
  head: function() {
    return seoMetadata({
      fullPath: this.$route.fullPath,
      metaDescription: this.metaData.metaDescription,
      metaTitle: this.metaData.metaTitle,
      keywords: this.metaData.keywords,
      metaImageUrl: this.metaData.metaImageUrl,
      metaVideoUrl: this.metaData.metaVideoUrl
    });
  }
};
</script>

<style lang="postcss" scoped>
#main-image {
  height: 32rem;
}

@media screen and (min-width: 768px) {
  .head {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 0;
  }

  #main-image {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    height: 561px;
  }

  #supported-list {
    grid-column: 1 / 3;
    grid-row: 2 / 2;
  }

  #main-info {
    grid-column: 2 / 2;
    grid-row: 1 / 1;
    height: 561px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 24px;
  }
}

.bg-controls {
  width: 100%;
  height: 180px;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
  transform: rotate(-180deg);
}
.teammates-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}
</style>
