<template>
  <InfiniteScroll
    :loading="loading"
    @loadMore="loadMore"
    :hasMoreToLoad="posts.nextToken ? true : false"
  >
    <PostModal
      :post="activePost"
      v-model="showPostModal"
      @requestedPrevious="requestedPrevious"
      @requestedNext="requestedNext"
    />
    <div
      class="container mx-auto relative w-full flex flex-row mt-14 h-11 mb-6 w-full max-w-desktop"
    >
      <BackButton
        type="dark"
        @click="
          $router.push({
            name: 'HomeIn'
          })
        "
        class="absolute left-4 top-0"
      />
      <div class="flex flex-grow justify-center">
        <h3>
          Posts
        </h3>
      </div>
    </div>
    <p v-if="displayName" class="font-tiny w-full text-center mb-2 capitalize">
      Posts from {{ displayName }}
    </p>
    <div
      v-if="postsLocal && postsLocal.length > 0"
      id="post-list"
      class="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 mx-auto w-full max-w-desktop px-6 md:px-0 mb-4"
    >
      <div
        v-for="(post, index) in postsLocal"
        :key="post.id"
        id="post"
        class="w-full text-center mx-auto my-0 relative rounded-sm overflow-hidden relative h-post-height-small w-post-width-small md:w-post-width-large md:h-post-height-large"
        @click="displayPost(post, index)"
      >
        <PostRender class="relative" :post="post" typeOfRender="list" />
      </div>
    </div>
  </InfiniteScroll>
</template>

<script>
import PostModal from "@/components/posts/PostModal.vue";
import PostRender from "@/components/posts/PostRender.vue";
import BackButton from "@/components/shared/BackButton.vue";
import { GET_TIMELINE_POSTS } from "@/graphql/queries/post/postsQueries.js";
import InfiniteScroll from "@/components/shared/InfiniteScroll.vue";

export default {
  name: "PostsPage",
  components: {
    BackButton,
    PostRender,
    PostModal,
    InfiniteScroll
  },
  data() {
    return {
      loading: true,
      activePost: null,
      activePostIndex: null,
      showPostModal: false,
      postsLocal: [],
      nextToken: null,
      teamId: "",
      displayName: "Recent Posts"
    };
  },
  beforeMount() {
    this.nextToken = null;
    this.postsLocal = [];
  },
  apollo: {
    posts: {
      query: GET_TIMELINE_POSTS,
      update(data) {
        if (!this.nextToken) {
          this.postsLocal = [];
        }
        this.postsLocal.push(...data.getMyTimeline.posts);
        this.$nextTick(() => {
          this.loading = false;
        });
        return data.getMyTimeline;
      },
      // can be improved by pushing only new items on the response and fetchPolicy cache-and-network
      fetchPolicy: "no-cache",
      variables() {
        return {
          nextToken: this.nextToken,
          limit: 5
        };
      }
    }
  },
  methods: {
    displayPost(post, index) {
      this.activePost = post;
      this.showPostModal = true;
      this.activePostIndex = index;
    },
    loadMore() {
      this.loading = true;
      this.nextToken = this.posts.nextToken;
    },
    requestedPrevious() {
      if (this.activePostIndex === 0) {
        this.showPostModal = false;
        this.activePostIndex = null;
      } else {
        this.activePostIndex--;
        this.activePost = this.postsLocal[this.activePostIndex];
      }
    },
    requestedNext() {
      if (
        this.activePostIndex >= this.postsLocal.length - 3 &&
        this.posts.nextToken &&
        !this.loading
      ) {
        this.loadMore();
      }
      if (
        this.activePostIndex === this.postsLocal.length - 1 &&
        !this.posts.nextToken
      ) {
        this.showPostModal = false;
        this.activePostIndex = null;
      } else {
        if (this.postsLocal[this.activePostIndex + 1]) {
          this.activePostIndex++;
          this.activePost = this.postsLocal[this.activePostIndex];
        }
      }
    }
  }
};
</script>
