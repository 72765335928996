<template>
  <div id="profile-action-modal">
    <BaseModal size="h-full" :bgColor="'bg-white'" v-model="showModal">
      <ul class="h-full w-full">
        <li class="w-full px-6">
          <button
            class="w-full flex justify-center"
            @click="$router.push({ name: 'AccountSettings' })"
          >
            <div
              class="w-full flex flex-row items-center border-b border-gray-200 border-solid h-20"
            >
              <div
                class="font-subtitle2 text-blue-900 flex-grow capitalize text-left"
              >
                {{ $t("message.settings") }}
              </div>
              <div class="w-14 flex justify-end">
                <img src="@/assets/img/icons/right-arrow.svg" alt="go" />
              </div>
            </div>
          </button>
        </li>
        <li class="w-full px-6">
          <button class="w-full flex justify-center">
            <div
              class="w-full flex flex-row items-center border-b border-gray-200 border-solid  h-20"
            >
              <div
                class="font-subtitle2 text-blue-900 flex-grow capitalize  text-left"
              >
                {{ $t("message.report-user") }}
              </div>
              <div class="w-14 flex justify-end">
                <img src="@/assets/img/icons/right-arrow.svg" alt="go" />
              </div>
            </div>
          </button>
        </li>
        <li class="w-full px-6">
          <button class="w-full flex justify-center">
            <div
              class="w-full flex flex-row items-center border-b border-gray-200 border-solid  h-20"
            >
              <div
                class="font-subtitle2 text-blue-900 flex-grow capitalize text-left"
              >
                {{ $t("message.block-user") }}
              </div>
              <div class="w-14 flex justify-end">
                <img src="@/assets/img/icons/right-arrow.svg" alt="go" />
              </div>
            </div>
          </button>
        </li>
        <li class="w-full px-6">
          <button class="w-full flex justify-center">
            <div
              class="w-full flex flex-row items-center border-b border-gray-200 border-solid  h-20"
            >
              <div
                class="font-subtitle2 text-blue-900 flex-grow capitalize text-left"
              >
                {{ $t("message.share") }}
              </div>
              <div class="w-14 flex justify-end">
                <img src="@/assets/img/icons/right-arrow.svg" alt="go" />
              </div>
            </div>
          </button>
        </li>
      </ul>
    </BaseModal>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showModal: this.value
    };
  },
  watch: {
    showModal(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.showModal = val;
    }
  }
};
</script>
<style lang="postcss">
#profile-action-modal {
  .modal-content {
    max-width: var(--desktop-post-width);
    height: auto;

    @apply rounded-md;
  }
}
</style>
