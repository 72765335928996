<template>
  <div>
    <div class="container md:px-0">
      <h4 class="capitalize text-left">
        <slot name="title"></slot>
      </h4>
    </div>
    <div id="activity-item-list" class="w-full mt-4">
      <div
        v-for="item in activity"
        :key="item.id"
        class="item flex flex-col w-auto justify-start"
        @click="
          $router.push({
            name: 'itemPage',
            params: { slug: item.slug }
          })
        "
      >
        <div
          class="image rounded-sm h-64 w-38 relative bg-no-repeat bg-center bg-cover overflow-hidden"
          :style="`background-image: url('${item.imageUrl}')`"
        >
          <div class="bg-shadow-2"></div>
          <div class="absolute left-4 bottom-4 font-tiny text-gray-50">
            {{ item.title }}
          </div>
          <img
            src="@/assets/img/icons/play.svg"
            alt="play"
            class="absolute left-2 top-2"
          />
        </div>
        <h5 class=" text-gray-700 mt-2 capitalize">
          {{ item.name }}
        </h5>
        <div class="font-tiny" v-if="item.score">
          {{ `+${item.score.points} points / +${item.score.mins} mins` }}
        </div>
        <div class="font-tiny" v-if="item.note">
          {{ `${item.note}` }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    activity: {
      type: Array,
      required: true
    }
  }
};
</script>
<style lang="postcss" scoped>
#activity-item-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em;
}

.bg-shadow-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgb(0 0 0 / 30.3%) 25%,
    rgb(4 132 202 / 0.604%) 50%,
    rgb(0 212 255 / 0%) 50%,
    rgb(0 0 0 / 30.3%) 75%
  );
}
</style>
