export function handleHomepageToast(vue) {
  // TODO: make all views able to show toast passed through route params
  const homepageToast = vue.$store.getters["general/getHomepageToast"];
  const routeToast = vue.$route.params.toast;
  const toastParameters = {
    position: vue.$store.getters["general/getToastPosition"],
    ...routeToast,
    ...homepageToast
  };
  if (toastParameters.message) {
    vue.$toast.open(toastParameters);
  }
  vue.$store.dispatch("general/resetHomepageToast");
}
