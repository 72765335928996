<template>
  <BaseConfirmationModal
    v-model="showModal"
    confirmText="Report user"
    cancelText="Cancel"
    @actionConfirmed="
      $router.push({
        name: 'ReportUser',
        params: {
          userToReport
        }
      })
    "
  >
    <p class="text-base font-semibold tracking-wide break-all p-4">
      {{ userToReport.displayName }}
    </p>
  </BaseConfirmationModal>
</template>

<script>
export default {
  name: "ReportUserModal",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    userToReport: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showModal: this.value
    };
  },
  watch: {
    showModal(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.showModal = val;
    }
  }
};
</script>
