<template>
  <div class="h-full">
    <template v-if="task">
      <router-link
        :to="{
          name: 'TaskPage',
          params: { slug: task.team.slug, taskId: task.id }
        }"
        >Switch to Task View</router-link
      ><br />
      <router-link
        :to="{
          name: 'TeamPage',
          params: { slug: task.team.slug }
        }"
        >Go to Team</router-link
      >
      <div v-if="paymentStep == 'start'">
        Donation Amount: {{ defaultCurrency.symbol
        }}<input v-model="amount" class="mx-2 px-1" />
      </div>
      <div v-else>
        You have chosen to donate
        <b>{{ defaultCurrency.symbol }}{{ amount }}</b> to
        <b>{{ task.beneficiary.name }}</b>
      </div>
      <div id="card-element" class="my-4">
        <!--Stripe.js Card Element-->
      </div>
      <div class="my-4">Status: {{ paymentFormStatus }}</div>

      <button
        @click="handleTaskCTA()"
        :disabled="paymentButtonDisabled"
        class="btn w-full bg-blue-900 text-white border border-blue-900 outline-none focus:outline-none flex text-center justify-center items-center px-6"
      >
        <div class="loading w-6 h-6" v-if="actionButtonBusy">
          <Spinner class="sm-button" />
        </div>
        <span class="mx-auto" v-else-if="paymentButtonDisabled">
          (disabled)
        </span>
        <span class="mx-auto" v-else>Step: {{ paymentStep }}</span>
      </button>
      <div>
        <h4>Test Cards:</h4>
        <ul>
          <li></li>
          <li>Payment succeeds: 4242 4242 4242 4242</li>
          <li>Authentication required: 4000 0025 0000 3155</li>
          <li>Payment is declined: 4000 0000 0000 9995</li>
          <li>
            These test card numbers work with any CVC, postal code (eg. 90210)
            and future expiry date.
          </li>
        </ul>
      </div>
    </template>
    <template v-else-if="loading">
      <TaskPageSkeleton />
    </template>
    <template v-else>
      <div class="w-full h-full flex flex-col relative  md:pt-6">
        <NotFound
          :title="$t('message.task-not-found')"
          :description="$t('message.task-not-found-description')"
        />
      </div>
    </template>
  </div>
</template>
<script>
import TaskPageSkeleton from "@/components/skeletons/TaskPageSkeleton.vue";
import Spinner from "@/components/shared/Spinner.vue";
import { GET_PAYMENT_INTENT } from "@/graphql/queries/user/userQueries.js";
import { TASK_ACTION_START_DONATION } from "@/graphql/queries/user/userMutations.js";
import { GET_TASK } from "@/graphql/queries/task/taskQueries.js";
import NotFound from "@/components/shared/NotFound";
import defaultCurrency from "@/constants/defaultCurrency.js";

export default {
  name: "DonationTaskPage",
  components: {
    Spinner,
    TaskPageSkeleton,
    NotFound
  },
  data() {
    return {
      actionButtonBusy: false,
      task: null,
      amount: 10,
      paymentIntent: {
        id: null,
        clientSecret: null
      },
      stripe: null,
      card: null,
      paymentButtonDisabled: false,
      paymentFormStatus: "",
      paymentStep: "start",
      loading: true
    };
  },
  computed: {
    defaultCurrency() {
      return defaultCurrency;
    }
  },
  apollo: {
    getTask: {
      query: GET_TASK,
      variables() {
        return {
          id: this.$route.params.taskId
        };
      },
      update(data) {
        if (data.getTask) {
          this.task = data.getTask;
        }
        this.loading = false;
        console.log(this.task);
      },
      //test if this property is needed
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    handleTaskCTA() {
      if (this.paymentStep == "start") {
        this.taskStartDonationTask();
      } else if (this.paymentStep == "card-entry") {
        this.submitCardForm();
      } else if (this.paymentStep == "poll-for-confirmation") {
        this.pollForConfirmation();
      }
    },
    taskStartDonationTask() {
      this.actionButtonBusy = true;
      this.$apollo
        .mutate({
          mutation: TASK_ACTION_START_DONATION,
          variables: {
            amount: this.amount * 100,
            currency: defaultCurrency.code,
            taskId: this.task.id,
            beneficiaryId: this.task.beneficiary.id
          },
          update: (_event, response) => {
            this.paymentIntent.id = response.data.taskActionStartDonation.id;
            this.paymentIntent.clientSecret =
              response.data.taskActionStartDonation.clientSecret;
            this.prepareCardForm();
          }
        })
        .then(() => {
          this.actionButtonBusy = false;
        })
        .catch(err => {
          console.error(err);
          this.actionButtonBusy = false;
        });
    },
    prepareCardForm() {
      this.paymentStep = "card-entry";
      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
      let elements = this.stripe.elements();
      this.card = elements.create("card");
      this.card.mount("#card-element");
      this.card.on("change", event => {
        if (event.empty) {
          this.paymentFormStatus = "Complete the form";
          this.paymentButtonDisabled = event.empty;
        } else if (event.error) {
          this.paymentFormStatus = event.error.message;
          this.paymentButtonDisabled = true;
        } else {
          this.paymentFormStatus = "";
          this.paymentButtonDisabled = false;
        }
      });
      this.paymentFormStatus = "Complete the form";
      this.paymentButtonDisabled = true;
    },
    submitCardForm() {
      this.actionButtonBusy = true;
      this.stripe
        .confirmCardPayment(this.paymentIntent.clientSecret, {
          payment_method: {
            card: this.card
          }
        })
        .then(result => {
          this.actionButtonBusy = false;
          if (result.error) {
            // Show error to your customer
            console.error(result.error.message);
            this.paymentFormStatus = result.error.message;
          } else {
            // The payment succeeded!
            console.log(result.paymentIntent.id);
            this.paymentStep = "poll-for-confirmation";
            this.paymentFormStatus =
              "Payment succeeded, now we can poll for confirmation.";
          }
        })
        .catch(err => {
          console.error(err);
          this.actionButtonBusy = false;
        });
    },
    pollForConfirmation() {
      console.log("polling...");
      this.actionButtonBusy = true;
      this.$apollo
        .query({
          query: GET_PAYMENT_INTENT,
          variables: {
            id: this.paymentIntent.id
          },
          fetchPolicy: "no-cache"
        })
        .then(response => {
          const status = response?.data?.getPaymentIntent?.status;
          console.log(response);
          console.log("status: ", status);
          if (status == "complete") {
            this.paymentFormStatus = "Payment Received, thank you!";
            this.paymentStep = "complete";
          } else {
            this.paymentFormStatus = "Payment not received yet...";
          }
          console.log(this.paymentFormStatus);
          this.actionButtonBusy = false;
        })
        .catch(err => {
          console.error(err);
          this.actionButtonBusy = false;
        });
    }
  }
};
</script>
