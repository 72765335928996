<template>
  <div class="flex flex-col w-full relative">
    <div class="w-full relative">
      <BackButton
        type="dark"
        @click="$router.go(-1)"
        class="absolute left-4 top-11"
      />
      <img
        class="w-full"
        v-if="profileBackgroundImage != ''"
        :src="profileBackgroundImage"
        alt="Cover Image"
      />
    </div>
    <div class="flex flex-col container">
      <h1 class="mt-7">
        Thanks for fighting Digital Poverty.
      </h1>
      <div class="font-body mt-4">
        We want to say a big thank you for joining the family. You can make a
        real difference in the world.
      </div>
    </div>
  </div>
</template>
<script>
import BackButton from "@/components/shared/BackButton.vue";

export default {
  components: {
    BackButton
  },
  data() {
    return {
      profileBackgroundImage: "/test-imgs/background-image.png"
    };
  }
};
</script>
