<template>
  <div class="w-full h-full max-w-desktop mx-auto">
    <SettingsContainer
      :header="$t('message.moderate-user-profile')"
      :centerDesktopHeader="false"
    >
      <ul class="h-full w-full">
        <li class="w-full h-20 flex justify-center">
          <router-link
            :to="{
              name: 'ModerateProfileEditPage',
              params: {
                profileId: $route.params.profileId
              }
            }"
            class="w-full flex flex-row items-center border-b border-gray-200 border-solid text-left capitalize"
          >
            <div class="font-subtitle2 text-blue-900 flex-grow">
              {{ $t("message.edit-profile") }}
            </div>
            <div class="w-14 flex justify-end">
              <img src="@/assets/img/icons/right-arrow.svg" alt="go" />
            </div>
          </router-link>
        </li>
        <li class="w-full h-20 flex justify-center">
          <div
            class="w-full flex flex-row items-center border-b border-gray-200 border-solid text-left"
          >
            <div
              class="font-subtitle2 text-blue-900 items-center flex-grow grid grid-cols-2 py-2-b"
            >
              <template v-if="!isProfileLoaded">
                <div class="justify-self-start"></div>
                <div class="justify-self-end"></div>
              </template>
              <template v-else-if="profile.cognitoUser.Enabled">
                <div class="justify-self-start">
                  {{ $t("message.moderate-profile-user-currently-enabled") }}
                </div>
                <div class="justify-self-end">
                  <button
                    type="button"
                    class="btn btn-sm bg-red text-white px-6 py-2 overflow-hidden disable-enable flex items-center justify-center"
                    @click="toggleUserEnabledStatus()"
                  >
                    <span v-if="!loading">
                      {{ $t("message.moderate-profile-disable-user") }}
                    </span>
                    <div class="loading w-6 h-6" v-else>
                      <Spinner class="sm-button" />
                    </div>
                  </button>
                </div>
              </template>
              <template v-else>
                <div class="justify-self-start">
                  {{ $t("message.moderate-profile-user-currently-disabled") }}
                </div>
                <div class="justify-self-end">
                  <button
                    class="btn btn-sm bg-green-800 text-white px-6 py-2 overflow-hidden disable-enable flex items-center justify-center"
                    @click="toggleUserEnabledStatus()"
                  >
                    <span v-if="!loading">
                      {{ $t("message.moderate-profile-enable-user") }}
                    </span>
                    <div class="loading w-6 h-6" v-else>
                      <Spinner class="sm-button" />
                    </div>
                  </button>
                </div>
              </template>
            </div>
          </div>
        </li>
        <li class="w-full h-20 flex justify-center">
          <router-link
            v-if="isProfileLoaded"
            :to="{
              name: 'ReportUser',
              params: {
                userToReport: profile
              }
            }"
            class="w-full flex flex-row items-center border-b border-gray-200 border-solid text-left capitalize"
          >
            <div class="font-subtitle2 text-blue-900 flex-grow">
              Report user
            </div>
            <div class="w-14 flex justify-end">
              <img src="@/assets/img/icons/right-arrow.svg" alt="go" />
            </div>
          </router-link>
        </li>
      </ul>
    </SettingsContainer>
  </div>
</template>
<script>
import SettingsContainer from "@/components/settings/SettingsContainer.vue";
import { MODERATOR_GET_PROFILE } from "@/graphql/queries/user/userQueries.js";
import {
  MODERATOR_DISABLE_USER,
  MODERATOR_ENABLE_USER
} from "@/graphql/queries/user/userMutations.js";
import Spinner from "@/components/shared/Spinner.vue";

export default {
  name: "AccountSettings",
  components: {
    SettingsContainer,
    Spinner
  },
  data() {
    return {
      loading: false,
      skipModeratorGetProfile: false,
      isProfileLoaded: false
    };
  },
  // computed: {
  // },
  apollo: {
    profile: {
      query: MODERATOR_GET_PROFILE,
      update(data) {
        this.skipModeratorGetProfile = true;
        this.isProfileLoaded = true;
        this.loading = false;
        return data.getProfile;
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
      variables() {
        return {
          profileId: this.$route.params.profileId
        };
      },
      skip() {
        return this.skipModeratorGetProfile;
      }
    }
  },
  methods: {
    toggleUserEnabledStatus() {
      this.loading = true;
      const mutation = this.profile.cognitoUser.Enabled
        ? MODERATOR_DISABLE_USER
        : MODERATOR_ENABLE_USER;
      this.$apollo
        .mutate({
          mutation: mutation,
          variables: {
            profileId: this.profile.id
          },
          update: (
            _cache,
            { data: { moderatorDisableUser, moderatorEnableUser } }
          ) => {
            if (moderatorDisableUser == false || moderatorEnableUser == false) {
              console.error("request failed");
            }
            this.skipModeratorGetProfile = false;
          }
        })
        .catch(err => {
          this.loading = false;
          console.error(err);
        });
    }
  }
};
</script>

<style scoped>
button.disable-enable {
  min-width: 140px;
}
</style>
