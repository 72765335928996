<template>
  <div>
    <BaseConfirmationModal
      v-model="showClosePostConfirmation"
      :confirmText="$t('message.discard')"
      :cancelText="$t('message.cancel')"
      @actionConfirmed="closePostCreatorConfirmed()"
    >
      <div class="flex flex-col text-center items-center justify-center">
        <template>
          <h4>{{ $t("message.discard-current-post") }}</h4>
        </template>
      </div>
    </BaseConfirmationModal>
    <input
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />
    <!-- image cropper -->
    <div
      class="h-full bg-black absolute top-0 left-0 w-full"
      v-if="showCropper"
    >
      <div
        class="h-full w-full mx-auto flex flex-col justify-center content-center"
        v-if="imgSrc != '' && showCropper"
      >
        <div
          class="header absolute flex flex-row justify-between px-6 w-full top-6 left-0 z-10"
        >
          <div class="text-left">
            <img
              class="icon-shadow"
              @click="closePostCreator()"
              src="@/assets/img/icons/close-arrow-white.svg"
              alt="close"
            />
          </div>
          <div
            v-if="cropImg == ''"
            @click.prevent="cropImage()"
            class="icon-shadow font-button uppercase text-white text-right"
          >
            {{ $t("message.save") }}
          </div>
        </div>
        <template v-if="uploading">
          <div class="flex justify-center items-center w-full h-full">
            <div class="loading">
              <Spinner class="lg-dark" />
            </div>
          </div>
        </template>
        <template v-else>
          <section
            class="cropper-area flex justify-center flex-col text-center w-full h-full mx-auto"
            :class="showPreview ? 'hidden' : 'block'"
          >
            <div
              class="img-cropper h-full w-full flex justify-center items-center"
            >
              <vue-cropper
                ref="cropper"
                :aspectRatio="cropperAspectRatio"
                :initialAspectRatio="cropperAspectRatio"
                :src="imgSrc"
                alt="Source Image"
                :img-style="{ height: '100%' }"
                :view-mode="0"
                :crop-box-resizable="false"
                :cropBoxMovable="false"
                :minCropBoxWidth="100"
                :minCropBoxHeight="100"
                :guides="true"
                drag-mode="move"
                :auto-crop-area="cropperSize"
                :min-container-width="300"
                :min-container-height="300"
                :background="false"
                :rotatable="true"
              />
            </div>
          </section>
          <section
            v-if="showPreview"
            class="preview-area flex flex-col items-center h-full w-full justify-center justify-items-center"
          >
            <div class="cropped-image w-full rounded-md overflow-hidden">
              <img
                class="w-full"
                v-if="cropImg"
                :src="cropImg"
                alt="Cropped Image"
              />
            </div>
          </section>
          <section class="w-full absolute bottom-0">
            <div class="actions relative">
              <button
                v-if="cropImg != ''"
                @click.prevent="uploadImageToS3()"
                class="btn bg-white text-black border border-blue-900 outline-none focus:outline-none flex text-center justify-center items-center px-6 absolute bottom-5 right-5"
              >
                <span class="mx-auto">
                  {{ $t("message.continue") }}
                </span>
              </button>
            </div>
          </section>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import Spinner from "@/components/shared/Spinner.vue";
import "cropperjs/dist/cropper.css";
import { GET_URL_FOR_IMAGE } from "@/graphql/imagesQueries.js";
import axios from "axios";

export default {
  components: {
    VueCropper,
    Spinner
  },
  props: {
    cropperAspectRatio: {
      type: Number,
      required: false,
      default: 1
    },
    cropperSize: {
      type: Number,
      required: false,
      default: 1
    },
    buttonId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      imgSrc: "",
      cropImg: "",
      fileExtension: "",
      data: null,
      uploading: false,
      newImageUrl: "",
      showCropper: false,
      showPreview: false,
      showClosePostConfirmation: false,
      i: 0
    };
  },
  mounted() {
    var el = document.getElementById(this.buttonId);
    el.addEventListener("click", () => {
      console.log("clicked", this.buttonId);
      this.showFileChooser();
    });
  },
  watch: {
    value(val) {
      this.newImageUrl = val;
      this.showPreview = false;
      this.uploading = false;
    }
  },
  methods: {
    closePostCreator() {
      this.showClosePostConfirmation = true;
    },
    closePostCreatorConfirmed() {
      this.$emit("discardAndClose");
      this.reset();
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.showPreview = true;
    },
    reset() {
      if (this.$refs?.cropper) {
        this.$refs.cropper.reset();
      }
      console.log("reset");
      this.imgSrc = "";
      this.cropImg = "";
      this.newImageUrl = "";
      this.uploading = false;
      this.showCropper = false;
      this.showPreview = false;
      this.showClosePostConfirmation = false;
    },
    setData() {
      if (!this.data) return;

      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      this.$emit("cropping", true);
      this.showPreview = false;
      const file = e.target.files[0];
      this.fileExtension = file.name.split(".").pop();

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = event => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(event.target.result);
          } else {
            this.$refs.cropper = event.target.result;
          }
        };

        reader.readAsDataURL(file);
        this.showCropper = true;
      } else {
        alert("Sorry, FileReader API not supported");
      }
      this.$refs.input.value = "";
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    cropAndShowPreview() {
      this.cropImage();
      this.showPreview = true;
    },
    async uploadImageToS3() {
      let today = new Date();
      const file = this.dataURLtoFile(
        this.cropImg,
        "post-image-" + today + "." + this.fileExtension
      );
      this.uploading = true;
      this.newImageUrl = null;
      this.i = this.i + 1;

      const contentType = file.type;
      const extension = "." + file.name.split(".").pop();
      const urlToUpload = await this.$apollo
        .query({
          query: GET_URL_FOR_IMAGE,
          variables: {
            contentType: contentType,
            extension: extension
          },
          fetchPolicy: "no-cache"
        })
        .then(res => {
          return res?.data?.getUploadUrl;
        })
        .catch(() => {
          console.log("error on apollo call getting signed url");
          return null;
        });
      if (urlToUpload) {
        await axios.put(urlToUpload, file, {
          headers: {
            "Content-Type": contentType
          }
        });
        const resultUrl = urlToUpload.split("?");
        this.newImageUrl = resultUrl[0];
        this.uploading = false;
        this.i = this.i + 1;
        this.imgSrc = "";
        this.showCropper = false;
      } else {
        console.log("image not valid?");
      }
      const newPost = {
        imageUrl: this.newImageUrl
      };
      this.$emit("uploadedImage", newPost);
      this.reset();
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    }
  }
};
</script>
<style lang="postcss" scoped>
input[type="file"] {
  display: none;
}

.content {
  display: flex;
  flex-direction: column;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #c00;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 128px !important;
  height: 128px !important;
  overflow: hidden;
  border-radius: 100%;

  img {
    width: 100% !important;
    height: 100% !important;
  }
}
</style>
<style>
.cropper-point {
  background-color: #99e192;
  height: 10px;
  opacity: 0.75;
  width: 10px;
}

.cropper-container {
  margin: 0 auto;
}

.cropper-view-box {
  display: block;
  height: 100%;
  outline: 4px solid #99e192;
  outline-color: rgb(153 225 146);
  overflow: hidden;
  width: 100%;
}
</style>
