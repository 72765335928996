<template>
  <div class="list-options" v-bind="$attrs">
    <div
      @click="expand = !expand"
      class="color-selector rounded-full border-white w-10 h-10 z-20"
      :style="`background-color: ${value}`"
    />
    <transition name="fade">
      <div class="option-sub-list" v-if="expand">
        <template v-for="(option, index) in options">
          <div
            v-if="option !== selected"
            :key="index"
            @click="selectColor(option)"
            class="color-selector rounded-full border-white w-10 h-10"
            :style="`background-color: ${option}`"
          />
        </template>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "ColorSelector",
  data() {
    return {
      expand: false
    };
  },
  props: {
    options: {
      type: Array,
      default() {
        return ["#F93A5B", "#DF7529", "#F6E47E", "#99E192"];
      }
    },
    value: {
      type: String,
      required: true
    },
    selected: {
      type: String,
      required: true
    }
  },
  methods: {
    selectColor(color) {
      this.$emit("input", color);
      this.expand = false;
    }
  }
};
</script>
<style lang="postcss" scoped>
.color-selector {
  border-width: 3px;
}

.fade-enter-active,
.fade-leave-active {
  top: 36px;
}

.fade-enter,
.fade-leave-to {
  top: 0;
}

.list-options {
  @apply overflow-hidden z-10 flex flex-col;

  .color-selector {
    @apply mt-2;
  }
}

.horizontal {
  .fade-enter-active,
  .fade-leave-active {
    left: 36px;
    top: 0;
  }

  .fade-enter,
  .fade-leave-to {
    top: 0;
    left: 0;
  }

  &.list-options {
    @apply flex-row overflow-hidden z-10;

    .option-sub-list {
      @apply flex flex-row;
    }

    .color-selector {
      @apply mt-0 ml-2;
    }
  }
}
</style>
