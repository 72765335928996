<template>
  <div>
    <div
      v-if="showNewPostButton && showNewPostButtonInternal"
      @click="showNewPostMenu = !showNewPostMenu"
      class="plus-icon bg-blue-900 rounded-full w-14 h-14 justify-center items-center flex fixed right-4 bottom-24 z-20 md:bottom-4"
      :class="{ rotate: showNewPostMenu }"
    >
      <img src="@/assets/img/icons/plus.svg" />
    </div>
    <NewPostMenu
      v-if="showNewPostMenu"
      :user="user"
      @selectedType="initPost"
      :postToTeam="teamId !== null"
    />
    <div
      v-if="showCurrentPost"
      class="w-full h-full fixed top-0 left-0 z-20 flex justify-center items-center"
    >
      <PostWrapper
        id="post-wrapper"
        class="relative z-20 h-full md:rounded-md md:overflow-hidden"
        :post="newPost"
        @close="closeCreator"
        :showOptions="postCreatedSuccess"
      >
        <div
          v-if="uploading"
          class="absolute top-0 left-0 w-full bg-gray-50 text-black h-11 flex justify-center font-body2 items-center z-20"
        >
          Uploading...
        </div>
        <div
          v-if="postCreatedSuccess"
          class="absolute bottom-0 left-0 w-full bg-black text-gray-50 h-11 flex justify-center font-body2 items-center z-20"
        >
          {{
            $t("message.your-postType-was-added", {
              postType:
                postType === "image-text"
                  ? $t("message.photo")
                  : $t("message.video")
            })
          }}
        </div>
      </PostWrapper>
    </div>
    <ImagePostCreator
      v-if="showImagePostCreator && !newPost"
      @postCreated="postCreated"
      @discardAndClose="closeCreator()"
    />
    <VideoPostCreator
      v-if="showVideoPostCreator && !newPost"
      @postCreated="postCreated"
      @discardAndClose="closeCreator()"
    />
  </div>
</template>
<script>
import NewPostMenu from "@/components/posts/NewPostMenu.vue";
import ImagePostCreator from "@/components/posts/ImagePostCreator.vue";
import VideoPostCreator from "@/components/posts/VideoPostCreator.vue";
import { CREATE_POST } from "@/graphql/queries/user/userMutations.js";
import PostWrapper from "@/components/posts/PostWrapper.vue";

export default {
  name: "PostCreator",
  components: {
    NewPostMenu,
    ImagePostCreator,
    VideoPostCreator,
    PostWrapper
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    showNewPostButton: {
      type: Boolean,
      default: true
    },
    teamId: {
      type: String || null,
      default: null
    }
  },
  data() {
    return {
      uploading: false,
      newPost: null,
      showNewPostButtonInternal: true,
      showNewPostMenu: false,
      showImagePostCreator: false,
      showVideoPostCreator: false,
      postCreatedSuccess: false,
      showCurrentPost: false,
      postType: ""
    };
  },
  methods: {
    initPost(postType) {
      this.showNewPostButtonInternal = false;
      this.showNewPostMenu = false;
      this.newPost = null;
      this.postCreatedSuccess = false;
      this.showCurrentPost = false;
      this.postType = postType;
      if (postType === "image-text") {
        this.showImagePostCreator = true;
      }
      if (postType === "video") {
        this.showVideoPostCreator = true;
      }
    },
    postCreated(post) {
      this.showCurrentPost = true;
      this.uploading = true;
      this.newPost = post;
      if (this.teamId && this.teamId !== "") {
        this.newPost.teamId = this.teamId;
      }
      this.$apollo
        .mutate({
          mutation: CREATE_POST,
          variables: {
            newPost: {
              ...this.newPost
            }
          },
          update: (data, data2) => {
            this.newPost = {
              ...this.newPost,
              id: data2.data.postCreate.id,
              creator: {
                id: this.user.id
              }
            };
          },
          error(err) {
            console.log(err);
          }
        })
        .then(res => {
          this.uploading = false;
          this.postCreatedSuccess = true;
          this.$emit("created", {
            ...this.newPost,
            publishState: "queued",
            creator: {
              displayName: this.user.attributes.displayName,
              profileImageUrl: this.user.attributes.profileImageUrl
            },
            id: res.data.postCreate.id
          });
        })
        .catch(error => {
          console.log("error", error);
          this.loading = false;
        });
    },
    closeCreator() {
      this.showImagePostCreator = false;
      this.showVideoPostCreator = false;
      this.showNewPostMenu = false;
      this.showCurrentPost = false;
      this.showNewPostButtonInternal = true;
    }
  }
};
</script>
<style lang="postcss" scoped>
#post-wrapper {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 600px) {
  #post-wrapper {
    max-width: var(--desktop-post-width);
    max-height: var(--desktop-post-height);
  }
}

.plus-icon {
  transition: all 0.1s ease-in-out;

  &.rotate {
    transform: rotate(45deg);
  }
}
</style>
