export default [
  {
    displayName: "Manchester City",
    screenName: "NeymarJr0123",
    type: "supporter"
  },
  {
    displayName: "Red Bull",
    screenName: "NeymarJr0123",
    type: "supporter"
  },
  {
    displayName: "AC Milan",
    screenName: "NeymarJr0123",
    type: "supporter"
  },
  {
    displayName: "Borussia Dortmund",
    screenName: "NeymarJr0123",
    type: "supporter"
  },
  {
    displayName: "Valencia",
    screenName: "NeymarJr0123",
    type: "supporter"
  },
  {
    displayName: "Borussia Mönchengladbach",
    screenName: "NeymarJr0123",
    type: "supporter"
  },
  {
    displayName: "Olympic Marseille",
    screenName: "NeymarJr0123",
    type: "supporter"
  },
  {
    displayName: "PSV Eindhoven",
    screenName: "NeymarJr0123",
    type: "supporter"
  },
  {
    displayName: "Stade Rennais",
    screenName: "NeymarJr0123",
    type: "supporter"
  },
  {
    displayName: "Fenerbahçe",
    screenName: "NeymarJr0123",
    type: "supporter"
  }
];
