const badgeDefaultsStyle = {
  pre_register: {
    textColor: "white",
    text: "Pre-register",
    backgroundColor: "#F93A5B",
    shape: "button",
    position: "top_left",
    borderColor: "white"
  },
  coming_soon: {
    textColor: "#000000",
    text: "COMING SOON",
    backgroundColor: "#F6E47E",
    shape: "circle",
    position: "top_right",
    borderColor: "#F6E47E"
  },
  default: {
    textColor: "#000000",
    text: "New",
    backgroundColor: "#F6E47E",
    shape: "circle",
    position: "top_right",
    borderColor: "#F6E47E"
  }
};

export default {
  computed: {
    showBadge() {
      if (
        this.team._badge.showAlways?.items.length > 0 &&
        this.team._badge.showAlways?.items[0]._system_.codename === "yes"
      )
        return true;

      if (
        this.team.status.items.length > 0 &&
        (this.team.status.items[0]._system_.codename === "coming_soon" ||
          this.team.status.items[0]._system_.codename === "pre_register")
      )
        return true;

      return false;
    },
    badge() {
      if (this.showBadge) {
        const badgeType =
          this.team.status.items.length > 0
            ? this.team.status.items[0]._system_.codename
            : null;
        let badge = null;
        if (badgeType && badgeDefaultsStyle[badgeType]) {
          badge = { ...badgeDefaultsStyle[badgeType] };
        } else {
          badge = { ...badgeDefaultsStyle.default };
        }

        badge.backgroundColor =
          this.team._badge.backgroundColor || badge.backgroundColor;
        badge.textColor = this.team._badge.textColor || badge.textColor;
        badge.borderColor = this.team._badge.borderColor || badge.borderColor;
        badge.text = this.team._badge.text || badge.text;

        badge.shape =
          this.team._badge.shape.items.length > 0 &&
          this.team._badge.shape.items[0]._system_.codename !== "use_default"
            ? this.team._badge.shape.items[0]._system_.codename
            : badge.shape;
        badge.position =
          this.team._badge.position.items.length > 0 &&
          this.team._badge.position.items[0]._system_.codename !== "use_default"
            ? this.team._badge.position.items[0]._system_.codename
            : badge.position;
        return badge;
      } else {
        return null;
      }
    }
  }
};
