<template>
  <section class="section-step-up px-4 py-8 sm:px-4 sm:py-12">
    <div class="h-full container mx-auto grid grid-cols-1 md:grid-cols-2">
      <div class="w-4/5 mx-auto md:m-auto order-last md:order-none">
        <h2 class="text-center md:text-left justify-center md:justify-start">
          Step up, join in,<br />
          make your mark
        </h2>
        <p class="text-center md:text-left mt-8">
          Join a global community of social actionists connecting virtually and
          physically in cause based teams to positively support people and
          planet.
        </p>
      </div>
      <img :src="imageUrl" alt="" class="m-auto" />
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeStepUp",
  data() {
    return {
      windowWidth: window.innerWidth,
      txt: ""
    };
  },
  computed: {
    imageUrl() {
      if (this.windowWidth > 767) {
        return require("../../assets/img/home/stepup.png");
      } else {
        return require("../../assets/img/home/stepup-mobile.png");
      }
    }
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      this.txt = `it changed to ${newWidth} from ${oldWidth}`;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    }
  }
};
</script>

<style scoped>
.section-step-up {
  background-color: #99e192;
}
</style>
