<template>
  <section class="section-tasks overflow-hidden">
    <div
      class="h-full container mx-auto grid grid-cols-1 md:grid-cols-2 my-12 md:my-20"
    >
      <div class="w-4/5 m-auto">
        <h2 class="text-center md:text-left mt-6 md:mt-0">
          Wherever you are, whatever your fight, whatever time you have, start
          today
        </h2>
        <p class="text-center md:text-left mt-8">
          Over 50 tasks set by some of the best performing non-profit orgs. From
          3 minutes to 3 months. Start creating your impact legacy.
        </p>
      </div>
      <img
        src="@/assets/img/home/nadia.png"
        alt=""
        class="m-auto order-first md:order-none"
      />
      <div
        class="h-56 lg:h-96 md:col-span-2 flex justify-evenly overflow-visible mt-8 md:mt-14"
      >
        <img
          src="@/assets/img/home/pledge.png"
          alt=""
          class="inline h-36 lg:h-56 self-end"
        />
        <img
          src="@/assets/img/home/watchshare.png"
          alt=""
          class="inline h-36 lg:h-56 self-center"
        />
        <img
          src="@/assets/img/home/challenge.png"
          alt=""
          class="inline h-36 lg:h-56 self-start"
        />
        <img
          src="@/assets/img/home/sponsor.png"
          alt=""
          class="inline h-36 lg:h-56 self-center"
        />
        <img
          src="@/assets/img/home/donate.png"
          alt=""
          class="inline h-36 lg:h-56 self-start"
        />
        <img
          src="@/assets/img/home/volunteer.png"
          alt=""
          class="inline h-36 lg:h-56 self-center"
        />
        <img
          src="@/assets/img/home/learn.png"
          alt=""
          class="inline h-36 lg:h-56 self-end"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeTasks"
};
</script>

<style scoped>
.section-tasks {
  background-color: #f2a4c4;
}
</style>
