<template>
  <div>
    <div class="grid grid-cols-2 px-4 md:px-0">
      <h4 class="capitalize text-left">
        <slot name="title"></slot>
      </h4>
      <div
        v-if="$scopedSlots.seeAll"
        class="text-right text-blue-900 font-button"
      >
        <slot name="seeAll"></slot>
      </div>
    </div>
    <div
      id="task-list"
      class="scrollbar-hidden w-full flex flex-row overflow-x-auto mt-4"
    >
      <template v-for="(taskSet, index) in splittedTasks">
        <div class="tasks-set flex flex-col" :key="index">
          <template v-for="(task, index2) in taskSet">
            <template v-if="task !== null">
              <TaskLink class="task-box" :task="task" :key="index2" />
            </template>
            <template v-else>
              <div :key="index2" class="mb-2">
                <div
                  class="h-48 w-48 bg-gray-200 rounded-sm overflow-hidden mr-4"
                ></div>
                <div class="h-24"></div>
              </div>
            </template>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { formatPointsOnCompletion } from "@/utils/basicHelpers.js";
import TaskLink from "@/components/tasks/TaskLink.vue";
import defaultCurrency from "@/constants/defaultCurrency.js";

export default {
  components: {
    TaskLink
  },
  props: {
    tasks: {
      type: Array,
      required: true
    }
  },
  computed: {
    splittedTasks() {
      const minCols = this.isDesktop ? 4 : 2;

      if (minCols >= this.tasks.length) {
        let tasks = this.tasks.map(task => {
          return [task];
        });

        let needAddEmpty = minCols > this.tasks.length;
        if (needAddEmpty) {
          let currentQty = tasks.length;
          for (let index = currentQty; index < minCols; index++) {
            tasks.push([null]);
          }
        }
        return tasks;
      }
      //desktop  => [[8,4],[7,3],[6,2],[5,1]]
      if (this.isDesktop) {
        var chunks = [],
          i = 0,
          n = 4;
        while (i < n) {
          chunks.push([this.tasks[i] ?? null, this.tasks[i + 4] ?? null]);
          i++;
        }
        return chunks;
      } else {
        //mobile => [[8,7],[6,5],[4,3],[2,null]]
        let divided = this.tasks.reduce((a, b) => {
          let res = [...a];
          if (a.length > 0) {
            if (res[res.length - 1].length > 1) {
              res.push([b]);
            } else {
              res[res.length - 1] = [...res[res.length - 1], b];
            }
          } else {
            res.push([b]);
          }
          return res;
        }, []);
        if (divided[divided.length - 1].length == 1) {
          divided[divided.length - 1].push(null);
        }
        return divided;
      }
    },
    isDesktop() {
      return this.$store.getters["general/isDesktop"];
    }
  },
  methods: {
    formatPointsOnCompletion,
    getPointsText(task) {
      if (task.taskType === "DonationTask") {
        return `${defaultCurrency.pointsPerUnit.short} per ${defaultCurrency.symbol}`;
      } else {
        return this.formatPointsOnCompletion(task.pointsOnCompletion) || 0;
      }
    }
  }
};
</script>
<style lang="postcss" scoped>
.tasks-set:first-child {
  @apply ml-4 md:ml-0;
}
.tasks-set:last-child {
  .task {
    @apply md:mr-0;
  }
}

.lives-impacted-pill {
  padding: 5.5px;
  font-size: 10px;
}
.task-box {
  @apply mr-3;
}
</style>
