<template>
  <div class="w-full">
    <div class="mb-2 header px-4 md:mb-6 md:px-0">
      <h4 class="capitalize text-left w-auto">
        <slot name="title"></slot>
      </h4>
      <div
        v-if="!hideShowAll"
        class="font-button uppercase text-blue-900 text-right"
      >
        {{ $t("message.see-all") }}
      </div>
    </div>
    <div
      id="profile-list"
      class="scrollbar-hidden w-full flex flex-row overflow-x-auto md:flex-col md:grid md:grid-cols-6 gap-4 md:gap-3 px-4 md:px-0"
    >
      <ProfileThumbnail
        class="profile-img inline-block mx-auto"
        v-for="(profile, index) in profiles"
        :key="index"
        :profile="profile"
        :profileType="profilesType"
      />
    </div>
  </div>
</template>
<script>
import ProfileThumbnail from "@/components/shared/ProfileThumbnail.vue";

export default {
  name: "ProfileList",
  components: {
    ProfileThumbnail
  },
  props: {
    profiles: {
      type: Array,
      required: true
    },
    hideShowAll: {
      type: Boolean,
      required: false,
      default: true // hidden for now
    },
    profilesType: {
      type: String,
      required: true
    }
  }
};
</script>
<style lang="postcss" scoped>
.header {
  display: grid;
  grid-template-columns: 1fr 90px;
}

.font-special {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
}
</style>
