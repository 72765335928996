<template>
  <div class="max-w-desktop mx-auto px-6">
    <LogoHeader />
    <BaseAlertModal
      @acepted="acepted()"
      :loading="loadingAlert"
      v-model="showAlert"
    >
      {{ $t("message.information-updated") }}
    </BaseAlertModal>
    <div class="mx-auto flex flex-grow flex-col w-full">
      <h2 class="capitalize mx-auto flex justify-center">
        {{ $t("message.date-of-birth") }}
      </h2>
      <div class="font-body mx-auto">
        {{ $t("message.must-be-at-least", { minAge: 12 }) }}.
      </div>
      <form class="h-full flex flex-col" @submit.prevent="next()">
        <InputWrapper
          v-model="date"
          class="mt-4"
          name="date"
          :label="$t('message.date-of-birth')"
          type="date"
          :error="hasError('date')"
          :touched="touched('date')"
          :hint="hint('date')"
          @input="validateForm()"
          @blur="changed('date')"
        />
        <div class="flex flex-grow items-end">
          <button
            type="submit"
            class="mx-auto btn btn-lg btn-default my-4 mb-8"
          >
            <span class="mx-auto" v-if="!loading">
              {{ $t("message.continue") }}
            </span>
            <div class="loading w-6 h-6" v-else>
              <Spinner class="sm-button" />
            </div>
          </button>
        </div>
      </form>
      <p class="text-blue my-4 uppercase mx-auto" v-if="error">{{ error }}</p>
      <a class="text-red mt-8 uppercase mx-auto" @click="logOut()">{{
        $t("message.logout")
      }}</a>
    </div>
  </div>
</template>

<script>
import InputWrapper from "@/components/form/InputWrapper.vue";
import LogoHeader from "@/components/shared/LogoHeader.vue";
import Spinner from "@/components/shared/Spinner.vue";
import { isValidAge } from "@/utils/basicHelpers.js";
export default {
  name: "BirthDateForm",
  components: {
    InputWrapper,
    LogoHeader,
    Spinner
  },
  data: () => {
    return {
      date: "",
      validation: {},
      showAllErrors: true,
      error: "",
      underTwelveError: false,
      step: 2,
      showAlert: false,
      loadingAlert: false,
      loading: false
    };
  },
  computed: {
    link() {
      return `<a class="text-red-900 font-semibold capitalize">${this.$t(
        "message.download-young-activist-pack"
      )}</a>`;
    },
    touched() {
      return key => {
        return this.validation[key]?.touched == true;
      };
    },
    hasError() {
      return key => {
        return (
          (this.validation[key]?.error == true &&
            (this.showAllErrors == true ||
              this.validation[key]?.touched == true)) ||
          false
        );
      };
    },
    hint() {
      return key => {
        return this.validation[key]?.touched || this.showAllErrors
          ? this.validation[key]?.hint || ""
          : "";
      };
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
    },
    changed(key) {
      if (!this.validation[key]) {
        this.validation[key] = {
          touched: true
        };
      } else {
        if (this.validation[key].touched == null) {
          this.validation[key].touched = true;
        } else {
          this.validation[key] = { ...this.validation[key], touched: true };
        }
      }
      this.showAllErrors = true;
      this.validateForm();
      this.$forceUpdate();
    },
    addError(key, message) {
      if (this.validation[key]) {
        this.validation[key].error = true;
        this.validation[key].hint = message;
      } else {
        this.validation[key] = {
          hint: message,
          error: true
        };
      }
    },
    clearError(key) {
      if (this.validation[key]) {
        this.validation[key].error = false;
        this.validation[key].hint = "";
      }
    },
    resetForm() {
      this.underTwelveError = false;
      this.validation = {};
    },
    validateForm() {
      let valid = true;
      //date under 12 validation
      if (this.date == "" || this.date == "NaN") {
        this.addError("date", "Invalid date");
        valid = false;
      } else {
        this.clearError("date");
        this.underTwelveError = false;
        if (!isValidAge(12, this.date)) {
          valid = false;
          this.addError("date", "You must be at least 12 to join");
          this.underTwelveError = true;
        }
      }
      return valid;
    },
    async next() {
      this.resetForm();
      this.showAllErrors = true;
      this.error = "";
      const valid = this.validateForm();
      if (valid || this.underTwelveError) {
        this.loading = true;
        const loggedUser = await this.$store.dispatch(
          "auth/updateUserDateOfBirth",
          this.date
        );
        this.loading = false;
        if (loggedUser) {
          this.showAlert = true;
        } else {
          this.error = "Error updating account";
        }
      }
    },
    acepted() {
      this.loadingAlert = true;
      if (this.underTwelveError) {
        this.$store.dispatch("auth/logout");
      } else {
        this.$router.push({ name: "HomeIn" });
      }
    }
  }
};
</script>
<style lang="postcss" scoped>
form {
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
}
</style>
