/* eslint-disable prettier/prettier */
<template>
  <RegisterContainer
    :header="$t('message.date-of-birth')"
    subtext="You must be at least 12 to join"
    @next="next()"
  >
    <InputWrapper
      v-model="date"
      class="mt-4 min-content mx-auto"
      name="date"
      type="date"
      @input="validateForm()"
      :error="hasError('date')"
      :touched="touched('date')"
      :hint="hint('date')"
      @blur="changed('date')"
      @changed="changed('date')"
    />
  </RegisterContainer>
</template>

<script>
import InputWrapper from "@/components/form/InputWrapper.vue";
import RegisterContainer from "@/components/register/RegisterContainer.vue";

export default {
  name: "BirthDateForm",
  components: {
    InputWrapper,
    RegisterContainer
  },
  data: () => {
    return {
      date: "",
      validation: {},
      showAllErrors: true,
      error: "",
      underTwelveError: false,
      step: null
    };
  },
  beforeMount() {
    this.step = this.$route.meta.step;
    if (
      this.$route.name != this.$store.getters["auth/getNewUserValidLocation"] &&
      this.$store.getters["auth/getNewUserStep"] < this.step - 1
    ) {
      this.$router.replace({
        name: this.$store.getters["auth/getNewUserValidLocation"]
      });
    }
  },
  mounted() {
    this.date = this.$store.getters["auth/getNewUserDateOfBirth"] || "";
  },
  computed: {
    link() {
      return `<a class="text-red-900 font-semibold capitalize">${this.$t(
        "message.download-young-activist-pack"
      )}</a>`;
    },
    touched() {
      return key => {
        return this.validation[key]?.touched == true;
      };
    },
    hasError() {
      return key => {
        return (
          (this.validation[key]?.error == true &&
            (this.showAllErrors == true ||
              this.validation[key]?.touched == true)) ||
          false
        );
      };
    },
    hint() {
      return key => {
        return this.validation[key]?.touched || this.showAllErrors
          ? this.validation[key]?.hint || ""
          : "";
      };
    }
  },
  methods: {
    changed(key) {
      if (!this.validation[key]) {
        this.validation[key] = {
          touched: true
        };
      } else {
        if (!this.validation[key].touched) {
          this.validation[key].touched = true;
        } else {
          this.validation[key] = { ...this.validation[key], touched: true };
        }
      }
      this.$forceUpdate();
    },
    addError(key, message) {
      if (this.validation[key]) {
        this.validation[key].error = true;
        this.validation[key].hint = message;
      } else {
        this.validation[key] = {
          hint: message,
          error: true
        };
      }
    },
    clearError(key) {
      if (this.validation[key]) {
        this.validation[key].error = false;
        this.validation[key].hint = "";
      }
    },
    resetForm() {
      this.underTwelveError = false;
      this.validation = {};
    },
    dateIsValid(date) {
      return date instanceof Date && !isNaN(date);
    },
    validateForm(forceShowError = false) {
      let valid = true;
      //date under 12 validation
      if (this.date == "" || this.date == "NaN") {
        this.addError("date", "Invalid date");
        valid = false;
      } else {
        this.clearError("date");
        this.underTwelveError = false;
        let dateParts = this.date.split("-");
        if (
          !forceShowError &&
          (dateParts[2] == "" || dateParts[1] == "" || dateParts[0] == "")
        ) {
          valid = false;
          return;
        }
        let userDate = new Date(
          `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
        );
        if (!this.dateIsValid(userDate)) {
          valid = false;
        }
        userDate.setFullYear(dateParts[2]);
        if (userDate.getFullYear() < 1900) {
          valid = false;
          this.addError("date", "Year is not valid");
        }
        let today = new Date();
        let yearsDiff = today.getFullYear() - userDate.getFullYear();
        if (yearsDiff < 12 && yearsDiff >= 0) {
          valid = false;
          this.addError("date", "You must be at least 12 to join");
          this.underTwelveError = true;
        }
        if (yearsDiff < 0) {
          valid = false;
          this.addError("date", "Year is not valid");
          this.underTwelveError = true;
        }
        if (yearsDiff == 12) {
          let monthsDif = today.getMonth() - userDate.getMonth();
          if (monthsDif < 0) {
            this.addError("date", "You must be at least 12 to join");
            this.underTwelveError = true;
            valid = false;
          } else {
            let daysDiff = today.getDate() - userDate.getDate();
            if (monthsDif == 0 && daysDiff < 0) {
              this.addError("date", "You must be at least 12 to join");
              this.underTwelveError = true;
              valid = false;
            }
          }
        }
      }
      return valid;
    },
    next() {
      this.resetForm();
      this.showAllErrors = true;
      const valid = this.validateForm(true);
      if (valid) {
        this.$store.dispatch("auth/setNewUserDateOfBirth", this.date);
        this.$store.dispatch("auth/setNewUserStepCompleted", this.step);
        this.$router.push({ name: "SignUpNameForm" });
      }
    }
  }
};
</script>
<style scoped>
.min-content {
  width: min-content;
}
</style>
