<template>
  <div class="w-full">
    <div class="grid grid-cols-2 mb-2 px-4 md:px-0">
      <h4 class="capitalize text-left">
        {{ title }}
      </h4>
      <div class="text-right">
        <slot name="seeAll" />
      </div>
    </div>
    <div
      id="post-list"
      class="scrollbar-hidden w-full flex flex-row overflow-x-auto relative"
    >
      <router-link
        v-for="team in teams"
        :key="team.id"
        class="post flex flex-col w-36 md:w-48"
        :to="{
          name: 'TeamPage',
          params: { slug: team.appsyncSlug }
        }"
      >
        <div
          class="image bg-gray-200 h-36 w-36 md:h-48 md:w-48 relative bg-no-repeat bg-center bg-cover"
          :style="`background-image: url('${team.imageDesktop.url}')`"
        ></div>
        <div class="font-special text-black mt-2">
          {{ team.title }}
        </div>
      </router-link>
      <template v-if="numOfPlaceholders > 0">
        <div
          v-for="index in numOfPlaceholders"
          :key="`placeholder-${index}`"
          class="post bg-gray-200 rounded-sm w-36 md:w-48 h-36 md:h-48"
        ></div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      postImg: "/test-imgs/team-bg.png"
    };
  },
  props: {
    teams: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters("general", ["isDesktop"]),
    numOfPlaceholders() {
      return (this.isDesktop ? 4 : 2) - this.teams?.length;
    }
  }
};
</script>
<style lang="postcss" scoped>
.font-special {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
}

.post {
  @apply mr-4 md:mr-3 md:hidden;
}

.post .image {
  @apply rounded-sm cursor-pointer;

  position: relative;
  overflow: hidden;
}

.post:first-child {
  @apply ml-4 md:ml-0;
}

.post:last-child {
  @apply md:mr-0;
}

.post:nth-child(1) {
  @apply md:block;
}

.post:nth-child(2) {
  @apply md:block;
}

.post:nth-child(3) {
  @apply md:block;
}

.post:nth-child(4) {
  @apply md:block md:mr-0;
}
</style>
