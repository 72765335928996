<template>
  <div
    id="metrics-box"
    class="bg-gray-100 rounded-sm text-center py-4 relative"
  >
    <LivesImpactedInfoModal v-model="showLivesImpactedInfoModal" />
    <InfoIcon
      v-if="showInfoIcon"
      class="absolute top-2 right-3 cursor-pointer"
      @infoClick="showLivesImpactedInfoModal = true"
    />
    <p class="flex justify-center text-xl font-semibold">
      <img :src="icon" alt="" class="inline mr-1" />
      {{ value | livesImpactedFormat }}
    </p>
    <label for="" class="font-normal capitalize"> {{ label }} </label>
  </div>
</template>

<script>
import LivesImpactedInfoModal from "@/components/shared/LivesImpactedInfoModal.vue";
import InfoIcon from "@/components/vectors/InfoIcon.vue";

export default {
  name: "MetricsBox",
  components: {
    LivesImpactedInfoModal,
    InfoIcon
  },
  props: {
    iconPath: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    showInfoIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon() {
      try {
        return require("@/" + this.iconPath);
      } catch (error) {
        return "";
      }
    }
  },
  data() {
    return {
      showLivesImpactedInfoModal: false
    };
  }
};
</script>

<style lang="postcss">
#lives-impacted-modal {
  .modal-content {
    max-width: var(--desktop-post-width);
    @apply sm:h-3/5;
  }
}
#metrics-box svg:hover {
  circle {
    fill: #f0f0e8;
  }
  path {
    fill: #63635d;
  }
  -webkit-transition: fill 100ms linear;
  -ms-transition: fill 100ms linear;
  transition: fill 100ms linear;
}
</style>
