<template>
  <div>
    <input
      ref="input"
      type="file"
      name="video"
      accept="video/*"
      @change="setVideo"
    />
    <div class="h-full bg-black absolute top-0 left-0 w-full" v-if="uploading">
      <div class="flex justify-center items-center w-full h-full">
        <div class="loading">
          <Spinner class="lg-dark" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/shared/Spinner.vue";
import "cropperjs/dist/cropper.css";
import { GET_URL_FOR_IMAGE } from "@/graphql/imagesQueries.js";
import axios from "axios";

export default {
  components: {
    Spinner
  },
  props: {
    buttonId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fileExtension: "",
      uploading: false,
      newVideoUrl: "",
      showClosePostConfirmation: false,
      i: 0,
      video: null
    };
  },
  mounted() {
    var el = document.getElementById(this.buttonId);
    el.addEventListener("click", () => {
      this.showFileChooser();
    });
  },
  watch: {
    value(val) {
      this.newVideoUrl = val;
      this.uploading = false;
    }
  },
  methods: {
    closePostCreator() {
      this.showClosePostConfirmation = true;
    },
    closePostCreatorConfirmed() {
      this.$emit("discardAndClose");
      this.reset();
    },
    reset() {
      this.newVideoUrl = "";
      this.uploading = false;
      this.showClosePostConfirmation = false;
    },
    setVideo(e) {
      const file = e.target.files[0];
      this.fileExtension = file.name.split(".").pop();

      if (file.type.indexOf("video/") === -1) {
        alert("Please select an image file"); // TODO - Add snackbar
        return;
      }

      this.video = file;
      this.$refs.input.value = "";
      this.uploadVideoToS3();
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    async uploadVideoToS3() {
      const file = this.video;
      this.uploading = true;
      this.newVideoUrl = null;
      this.i = this.i + 1;

      const contentType = file.type;
      const extension = "." + file.name.split(".").pop();
      const urlToUpload = await this.$apollo
        .query({
          query: GET_URL_FOR_IMAGE,
          variables: {
            contentType: contentType,
            extension: extension
          },
          fetchPolicy: "no-cache"
        })
        .then(res => {
          return res?.data?.getUploadUrl;
        })
        .catch(() => {
          console.log("error on apollo call getting signed url");
          return null;
        });
      if (urlToUpload) {
        await axios.put(urlToUpload, file, {
          headers: {
            "Content-Type": contentType
          }
        });
        const resultUrl = urlToUpload.split("?");
        this.newVideoUrl = resultUrl[0];
        this.i = this.i + 1;
        this.uploading = false;
      } else {
        console.log("Video not valid?");
      }
      this.$emit("videoUploaded", this.newVideoUrl);
    }
  }
};
</script>
<style lang="postcss" scoped>
input[type="file"] {
  display: none;
}
</style>
