const fetch = require("node-fetch");
const fs = require("fs");

const exportFragmentTypes = () => {
  return fetch(process.env.VUE_APP_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": process.env.VUE_APP_WEB_GUEST_USER_API_KEY
    },
    body: JSON.stringify({
      variables: {},
      query: `
        {
          __schema {
            types {
              kind
              name
              possibleTypes {
                name
              }
            }
          }
        }
      `
    })
  })
    .then(result => result.json())
    .then(result => {
      // here we're filtering out any type information unrelated to unions or interfaces
      const filteredData = result.data.__schema.types.filter(
        type => type.possibleTypes !== null
      );
      result.data.__schema.types = filteredData;
      fs.writeFileSync("./src/fragmentTypes.json", JSON.stringify(result.data));
    })
    .then(() => {
      console.log(`Fragment types exported for ${process.env.VUE_APP_API_URL}`);
    });
};

const defaultMode = "development";

const wrapServiceCommand = callback => {
  return (...args) => {
    exportFragmentTypes()
      .then(() => {
        return callback(...args);
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  };
};

module.exports = api => {
  const { serve, build } = api.service.commands;
  const serveFn = serve.fn;
  const buildFn = build.fn;
  serve.fn = wrapServiceCommand(serveFn);
  build.fn = wrapServiceCommand(buildFn);

  api.registerCommand(
    "exportFragmentTypes",
    {
      description:
        "Exports the GraphQL Fragment Types json for use with Apollo",
      usage: "vue-cli-service export-fragment-types"
    },
    () => {
      exportFragmentTypes().catch(err => {
        console.error(err);
        throw err;
      });
    }
  );
};

// https://cli.vuejs.org/dev-guide/plugin-dev.html#specifying-mode-for-commands
module.exports.defaultModes = {
  exportFragmentTypes: defaultMode,
  serve: defaultMode,
  build: defaultMode
};
