import axios from "axios";
// import camelize from "camelize";
import router from "@/router";
import { saveState, getActualToken } from "@/utils/localStorageHelper.js";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Authorization: null
  },
  withCredentials: false,
  transformResponse: [
    function(data) {
      // return data ? camelize(JSON.parse(data)) : null;
      return data ? JSON.parse(data) : null;
    }
  ]
});

http.interceptors.request.use(
  config => {
    const token = getActualToken();
    if (token && token != null) {
      config.headers["Authorization"] = token;
    } else {
      delete config.headers["Authorization"];
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// Add a response interceptor
http.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // eslint-disable-next-line no-console
    // console.log("response", response);
    return response;
  },
  function(error) {
    // eslint-disable-next-line no-console
    if (error?.response?.status == 401) {
      saveState("auth.currentUser", null);
      router.push("login");
      return false;
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default http;
