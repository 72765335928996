<template>
  <div v-if="teams && teams.length">
    <section
      v-for="(team, i) in teams"
      :key="`team-${i}`"
      class="section-team"
      :style="`background-color: ${getBgColor(i)}`"
    >
      <div
        class="h-full grid grid-cols-1 lg:grid-cols-2 xl:container mx-auto p-0 lg:p-4 bg-cover bg-no-repeat"
        :style="`background-image: url(${getBgImage(i)})`"
      >
        <div
          class="w-full 2xl:w-4/5 flex flex-col text-white mx-auto justify-end lg:justify-center"
          :class="{ 'lg:col-start-2': isTextPositionedRight(i) }"
        >
          <div class="text-bg">
            <h2 class="h-team">
              {{ team.header }}
            </h2>
            <p class="p-team my-4">
              {{ team.description }}
            </p>
            <button
              class="w-56 bg-white text-black border-black rounded-full font-medium uppercase py-5 mb-4 lg:mb-0 md:mr-4 hover:bg-gray-100 hover:shadow-lg"
              @click="
                $router.push({
                  name: 'TeamPage',
                  params: { slug: team.teamSlug }
                })
              "
            >
              {{ team.buttonText }}
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { KENTICO_GET_HOME_TEAMS } from "@/kentico-api/home.js";
import { mapGetters } from "vuex";

export default {
  name: "HomeTeam",
  computed: {
    ...mapGetters("general", ["isDesktop"]),
    bgImage() {
      return this.isDesktop
        ? this.team.imageDesktop.url
        : this.team.imageMobile.url;
    }
  },
  apollo: {
    teams: {
      client: "kenticoClient",
      query: KENTICO_GET_HOME_TEAMS,
      variables() {
        return {
          codename: "home_page___teams"
        };
      },
      update(data) {
        return data.homePageTeams.teams.items;
      },
      //test if this property is needed
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    getBgImage(i) {
      return this.isDesktop
        ? this.teams[i].imageDesktop.url
        : this.teams[i].imageMobile.url;
    },
    getBgColor(i) {
      return this.teams[i].colorHexCode ? this.teams[i].colorHexCode : "#000";
    },
    isTextPositionedRight(i) {
      if (this.teams[i].textPosition?.items[0]?._system_.codename === "right") {
        return true;
      } else {
        // default
        return false;
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
@media screen and (max-width: 1023px) {
  .text-bg {
    background: linear-gradient(180deg, rgb(0 0 0 / 50%) 0%, #000 100%);
    @apply pt-2 px-4 pb-8;
  }
}

.section-team {
  height: 631px;
}

@media screen and (min-width: 768px) {
  .section-team {
    height: 806px;
  }
  .h-team {
    font-size: 58px;
    line-height: 70px;
  }
  .p-team {
    line-height: 34px;
  }
}
</style>
