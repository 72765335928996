<template>
  <footer class="w-full text-center mt-12 mb-6 font-body">
    <img
      class="w-48 md:w-72 mx-auto mb-4"
      src="@/assets/img/logos/football_for_the_goals.png"
      alt="Football for the goals"
    />
    <p class="font-body mb-20 md:mb-14">
      An official solutions partner of the United Nations Football for the Goals
    </p>
    <a
      href="https://www.gameofourlives.org/docs/terms-conditions"
      class="mx-2 text-blue-900 hover:text-blue-500"
      target="_blank"
      >Terms & conditions</a
    >
    |
    <a
      href="https://www.gameofourlives.org/docs/privacy-policy"
      class="mx-2 text-blue-900 hover:text-blue-500"
      target="_blank"
      >Privacy policy</a
    >
    <br />
    <a
      href="https://www.gameofourlives.org/"
      class="text-blue-900 mx-2 hover:text-blue-500"
      target="_blank"
      >Game of our lives foundation</a
    >
  </footer>
</template>

<script>
export default {
  name: "HomeFooter"
};
</script>
