<template>
  <RegisterContainer
    :header="$t('message.enter-your-verification')"
    :loading="loading"
    @next="submitVerificationCode()"
  >
    <InputWrapper
      class="mt-4"
      name="email"
      :label="$t('message.your-email-address')"
      type="email"
      :error="hasError('email')"
      :touched="touched('email')"
      :placeholder="$t('message.email-address')"
      :hint="hint('email')"
      v-model="email"
      @input="validateForm()"
      @blur="changed('email')"
      v-if="!emailCached"
    />
    <InputWrapper
      class="mt-4"
      name="code"
      :label="$t('message.verification-code')"
      type="text"
      :error="hasError('code')"
      :touched="touched('code')"
      :placeholder="$t('message.verification-code')"
      :hint="hint('code')"
      v-model="code"
      @input="validateForm()"
      @blur="changed('code')"
    />
    <div class="mt-8 text-red text-center" v-if="response">
      {{ response }}
    </div>
    <router-link
      class="block mx-auto text-center text-blue-900 uppercase text-sm font-medium mt-11"
      :to="{ name: 'RequestCode' }"
    >
      {{ $t("message.didnt-get-an-email") }}
    </router-link>
  </RegisterContainer>
</template>

<script>
import InputWrapper from "@/components/form/InputWrapper.vue";
import RegisterContainer from "@/components/register/RegisterContainer.vue";
import CodeVerificationMixin from "@/mixins/CodeVerificationMixin.js";

export default {
  name: "EnterCode",
  mixins: [CodeVerificationMixin],
  components: {
    InputWrapper,
    RegisterContainer
  }
};
</script>
