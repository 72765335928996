<template>
  <section class="section-metrics">
    <div class="h-full container mx-auto px-4 py-12 md:py-20">
      <div
        class="w-full xl:w-4/5 2xl:w-3/5 grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-4 items-center m-auto"
      >
        <img
          src="@/assets/img/home/metrics.png"
          alt=""
          class="self-start mx-auto"
        />
        <div class="">
          <img
            src="@/assets/img/home/metrica.png"
            alt="SIMetrica Jacobs logo"
          />
          <h2 class="text-center md:text-left">
            The new standard in social action metrics. Game of our Lives
          </h2>
          <p class="text-center md:text-left">
            The first social good platform to use internationally-endorsed
            methods as set out by the OECD and governments globally, powered by
            Simetrica Jacobs and tied to UN SDGs.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeMetrics"
};
</script>

<style>
.section-metrics {
  background: #f0f0e8;
}
</style>
