<template>
  <button
    :class="{ 'bg-gray-400 bg-opacity-60 rounded-full': showBackground }"
    class="h-14 w-14 flex justify-center items-center"
    @click="$emit('click')"
  >
    <Settings v-if="isMyProfile" :fill="color" />
    <Report v-else :fill="color" />
  </button>
</template>

<script>
import Settings from "@/components/vectors/Settings.vue";
import Report from "@/components/vectors/Report.vue";

export default {
  name: "ProfileActionButton",
  components: {
    Settings,
    Report
  },
  props: {
    isMyProfile: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: "white"
    },
    showBackground: {
      type: Boolean,
      default: false
    }
  }
};
</script>
