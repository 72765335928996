<template>
  <div class="mx-auto flex flex-grow flex-col w-full">
    <h2 class="capitalize mx-auto flex justify-center">
      {{ $t("message.check-your-inbox") }}
    </h2>
    <div class="flex flex-grow flex-col">
      <img
        @click="$router.push({ name: 'CreatePassword' })"
        class="w-32 mx-auto mt-24 mb-20"
        src="@/assets/img/icons/mail.svg"
        alt="email"
      />
      <a class="font-button text-blue-900 text-center">
        {{ $t("message.didnt-get-email") }}
      </a>
    </div>
    <div class="flex items-end"></div>
  </div>
</template>

<script>
export default {
  name: "NameForm",
  components: {},
  data: () => {
    return {};
  },
  beforeMount() {
    if (!this.$store.getters["auth/getNewUserCreatedConfirmed"]) {
      this.$router.replace({
        name: this.$store.getters["auth/getNewUserValidLocation"]
      });
    }
  },
  computed: {},
  methods: {}
};
</script>
