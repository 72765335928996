<template>
  <div class="md:mt-8 h-full">
    <template v-if="profile">
      <div v-if="isDesktop" class="flex justify-between">
        <BackButton @click="$router.back()" type="dark" text="Back" />
        <ProfileActionButton
          :isMyProfile="isMyProfile"
          :showBackground="false"
          color="black"
          @click="handleProfileAction()"
        />
      </div>
      <div
        class="w-full relative bg-cover bg-center md:mt-8 md:bg-white flex flex-col justify-center items-center md:rounded-t-md overflow-hidden"
      >
        <img :src="profileBgImage" alt="profile-background" class="w-full" />
        <BackButton
          v-if="!isDesktop"
          @click="$router.go(-1)"
          class="absolute left-4 top-12 visible md:invisible"
        />
        <ProfileActionButton
          class="absolute right-4 top-12 visible md:invisible"
          :isMyProfile="isMyProfile"
          :showBackground="true"
          color="white"
          @click="handleProfileAction()"
        />
        <Avatar
          class="profile-img h-36 w-36 bg-white border-8 border-white rounded-full object-cover text-4xl md:text-7xl"
          avatarType="large"
          text="large"
          :clickable="false"
          :displayName="displayName"
          :profileImageUrl="profile.profileImageUrl"
        />
      </div>
      <section class="rounded-md md:bg-white pb-8 sm:pb-20">
        <div class="sm:w-3/5 md:w-2/5 px-4 mx-auto">
          <h2 class="pt-4 text-center">
            {{ profile.displayName }}
          </h2>
          <p class="font-body2 text-center my-4">
            {{ profile.bio }}
          </p>
          <router-link
            v-if="isMyProfile"
            class="btn btn-clear mt-4"
            :to="{ name: 'ProfileEditPage' }"
          >
            {{ $t("message.edit-profile") }}
          </router-link>
          <MetricsBox
            v-once
            class="w-full mt-6"
            iconPath="assets/img/icons/heart.svg"
            :label="$t('message.lives-impacted')"
            :value="standardizeAppsyncPoints(profile.pointsAwarded)"
            :showInfoIcon="true"
          />
        </div>
      </section>
      <div class="sm:mt-10" v-if="teamMemberships.length > 0">
        <TeamsList :teams="teamMemberships" title="teams">
          <template v-if="teamMemberships.teams > 4" v-slot:seeAll>
            <router-link
              class="font-button uppercase text-blue-900 text-right"
              :to="{
                name: 'ProfileTeamsPage',
                params: { screenName: profile.screenName }
              }"
            >
              {{ $t("message.see-all") }}
            </router-link>
          </template>
        </TeamsList>
      </div>
      <div class="mt-10" v-if="completedTasks && completedTasks.length > 0">
        <TasksCompletedList :tasks="completedTasks" class="my-8">
          <template v-slot:title>
            {{ $t("message.completed-tasks") }}
          </template>
        </TasksCompletedList>
      </div>
      <SocialShareGeneric
        class="my-8"
        :url="profileUrl"
        :shareText="profile.displayName"
        :data="shareData"
        type="profile"
      />
      <ReportUserModal v-model="showReportUserModal" :userToReport="profile" />
    </template>
    <template v-else-if="loading">
      <ProfilePageSkeleton />
    </template>
    <template v-else>
      <NotFound
        :title="$t('message.profile-not-found')"
        :description="$t('message.profile-not-found-description')"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_OTHER_PROFILE } from "@/graphql/queries/user/userQueries.js";
import { KENTICO_GET_PROFILE_TEAMS_AND_TASKS } from "@/kentico-api/teams.js";
import ProfilePageSkeleton from "@/components/skeletons/ProfilePageSkeleton.vue";
import NotFound from "@/components/shared/NotFound.vue";
import Avatar from "@/components/shared/Avatar.vue";
import MetricsBox from "@/components/shared/MetricsBox.vue";
import BackButton from "@/components/shared/BackButton.vue";
import ProfileActionButton from "./ProfileActionButton.vue";
import ReportUserModal from "./ReportUserModal.vue";
import TeamsList from "@/components/profile/TeamsList.vue";
import TasksCompletedList from "@/components/shared/TasksCompletedList.vue";
import SocialShareGeneric from "@/components/posts/SocialShareGeneric.vue";
import { seoMetadata } from "@/utils/seoMetadata";
import { standardizeAppsyncPoints } from "@/utils/basicHelpers.js";

export default {
  name: "ProfilePage",
  components: {
    ProfilePageSkeleton,
    NotFound,
    Avatar,
    MetricsBox,
    BackButton,
    ProfileActionButton,
    ReportUserModal,
    TeamsList,
    TasksCompletedList,
    SocialShareGeneric
  },
  data() {
    return {
      showAllErrors: false,
      loading: true,
      showNewPostModal: false,
      showPostModal: false,
      showReportUserModal: false,
      localPosts: null,
      nextToken: null,
      completedTasks: [],
      teamMemberships: []
    };
  },
  computed: {
    ...mapGetters("general", ["isDesktop"]),
    displayName() {
      return this.profile?.displayName;
    },
    profileBgImage() {
      return (
        this.profile.backgroundImageUrl ||
        require("@/assets/img/hardcoded/default-user-background.svg")
      );
    },
    profileAvatarImage() {
      return this.profile.profileImageUrl;
    },
    profileUrl() {
      let props = this.$router.resolve({
        name: "ProfilePage",
        params: { screenName: this.profile?.screenName }
      });
      return process.env.VUE_APP_WEB_URL + props.href;
    },
    isMyProfile() {
      if (!this.userIsLoggedIn()) {
        return false;
      }
      return (
        !this.$route.params?.screenName?.toLowerCase() ||
        this.$store.getters[
          "auth/currentUser"
        ]?.attributes.screenName.toLowerCase() ==
          this.$route.params?.screenName?.toLowerCase() ||
        this.$store.getters["auth/currentUser"]?.id ==
          this.$route.params?.profileId
      );
    },
    userCanModerate() {
      if (!this.userIsLoggedIn()) {
        return false;
      }
      let result = ["Admin", "Moderator"].some(el =>
        this.$store.getters["auth/currentUser"].groups.includes(el)
      );
      return result;
    },
    shareData() {
      return {
        profile: {
          id: this.profile.id,
          screenName: this.profile.screenName
        }
      };
    }
  },
  apollo: {
    profile: {
      query: GET_OTHER_PROFILE,
      update(data) {
        //can modify the response here
        if (data.getProfile) {
          this.localPosts = { ...data.getProfile.posts };
          this.getTeamsAndTasks(
            data.getProfile.teamMemberships.teams.map(
              team => team.kenticoCodename
            ),
            data.getProfile.tasks.tasks.map(task => task.kenticoCodename)
          );
        }
        return data.getProfile;
      },
      // TODO - test if this property is needed
      // if need to add cache will find a problem like this
      // https://jacky-ttt.medium.com/day121-warning-heuristic-fragment-matching-going-on-8208b584cb5e
      fetchPolicy: "no-cache",
      errorPolicy: "all",
      variables() {
        return {
          screenName:
            this.$route.params.screenName ||
            this.$store.getters["auth/currentUser"]?.attributes.screenName
        };
      }
    }
  },
  methods: {
    standardizeAppsyncPoints,
    getTeamsAndTasks(teamsCodenames, tasksCodenames) {
      const noTeams = teamsCodenames.length == 0;
      const noTasks = tasksCodenames.length == 0;
      if (noTeams && noTasks) {
        this.loading = false;
        return;
      }
      this.$apollo
        .query({
          client: "kenticoClient",
          query: KENTICO_GET_PROFILE_TEAMS_AND_TASKS,
          variables: {
            teamsCodenames: teamsCodenames,
            tasksCodenames: tasksCodenames,
            includeTeams: !noTeams,
            includeTasks: !noTasks
          },
          fetchPolicy: "no-cache"
        })
        .then(response => {
          this.teamMemberships = response.data.team_All?.items || [];
          this.completedTasks = (response.data.task_All?.items || []).flatMap(
            kenticoTask => {
              // find an appsync match
              const appsyncTask = this.profile.tasks.tasks.find(appsyncTask => {
                return (
                  appsyncTask.kenticoCodename === kenticoTask._system_.codename
                );
              });

              if (appsyncTask) {
                return {
                  ...kenticoTask,
                  id: appsyncTask.id,
                  team: appsyncTask.team
                };
              }
              // if no results found return empty array, so flatmap can ignore this record
              return [];
            }
          );
          this.loading = false;
        });
    },
    userIsLoggedIn() {
      return this.$store.getters["auth/isLogged"];
    },
    handleProfileAction() {
      if (this.isMyProfile) {
        // show settings
        this.$router.push({ name: "AccountSettings" });
      } else if (this.userCanModerate) {
        this.$router.push({
          name: "ModerateProfilePage",
          params: { profileId: this.profile.id }
        });
      } else {
        // show report user modal
        this.showReportUserModal = true;
      }
    }
  },
  head: function() {
    return seoMetadata({
      fullPath: this.$route.fullPath,
      metaDescription: this.$t("message.default-og-description"),
      metaTitle: this.$t("message.default-og-title"),
      metaImageUrl: process.env.VUE_APP_WEB_URL + "/img/share-logo.png"
    });
  }
};
</script>
<style scoped>
.profile-img {
  margin-top: -44px;
  @media screen and (min-width: 768px) {
    margin-top: -72px;
  }
}
</style>
