<template>
  <router-link
    :to="goToProfile()"
    class="nominated w-24 items-center cursor-pointer"
  >
    <div
      class="image bg-gray-200 h-24 w-24 relative bg-no-repeat bg-center bg-cover flex-shrink-0"
    >
      <img
        v-if="profile.avatar.url"
        :src="profile.avatar.url"
        class="h-full object-cover"
        alt="Profile picture"
      />
    </div>
    <label
      class="block w-full text-center text-xs text-black font-normal overflow-ellipsis overflow-hidden flex-nowrap break-words mt-1"
    >
      {{ profile.name }}
    </label>
  </router-link>
</template>

<script>
export default {
  name: "ProfileThumbnail",
  props: {
    profile: {
      type: Object,
      required: true
    },
    profileType: {
      type: String,
      required: false
    }
  },
  methods: {
    goToProfile() {
      if (this.profileType === "captain") {
        return {
          name: "CaptainProfilePage",
          params: {
            profileSlug: this.profile.slug
          }
        };
      } else if (this.profileType === "supporter") {
        return {
          name: "SupporterProfilePage",
          params: {
            profileSlug: this.profile.slug
          }
        };
      } else {
        return {
          name: "ProfilePage",
          params: { profileId: this.profile.id }
        };
      }
    }
  }
};
</script>

<style>
.nominated .image {
  width: 96px;
  height: 96px;
  overflow: hidden;
  border-radius: 100%;
}
</style>
