<template>
  <div class="h-full w-full md:py-6 relative">
    <div
      id="inboxPage"
      class="relative h-full h-14 w-14d md:bg-gray-100 md:rounded-md overflow-x-hidden overflow-y-auto flex flex-row"
      v-if="loading || inboxMessages.length > 0"
    >
      <InboxSkeleton v-if="loading" />
      <div
        v-else-if="inboxMessages.length > 0"
        id="messages"
        class="w-full h-auto overflow-x-hidden scrollbar-hidden"
        :class="{ minimize: !closedMessage, closed: closedMessage === null }"
      >
        <button
          @click="inboxMessages = []"
          class="absolute top-0 right-2 text-gray-600"
        >
          clear
        </button>
        <div
          v-for="(message, index) in inboxMessages"
          :key="index"
          class="w-full border-b border-gray-200 border-solid py-6 px-4"
          :class="{
            'md:bg-gray-200': activeMessage && activeMessage.id == message.id
          }"
          @click="openMessage(message)"
        >
          <div class="w-full flex flex-row overflow-hidden">
            <div class="profile mr-6">
              <img :src="message.sender.image" alt="" />
            </div>
            <div class="flex flex-col">
              <div class="font-body2 text-indigo-900 text-opacity-50">
                {{ message.sender.displayName }}
              </div>
              <h4 class="text-indigo-900">
                {{ message.subject }}
              </h4>
              <div class="text-indigo-900 font-body2 message-preview-content">
                {{ message.content }}
              </div>
              <div class="text-indigo-900 font-tiny">
                {{ message.timeAgo }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="activeMessage"
        id="active-message"
        class="bg-white p-10"
        :class="{ inactive: closedMessage }"
      >
        <template v-if="activeMessage">
          <button
            @click="closedMessage = true"
            class="absolute top-4 right-4 w-6"
          >
            <img src="@/assets/img/icons/exit-icon.svg" alt="close" />
          </button>
          <div class="flex flex-row items-center">
            <div class="mr-6 w-12 h-12 rounded-full overflow-hidden">
              <img :src="activeMessage.sender.image" alt="" />
            </div>
            <h2>{{ activeMessage.sender.displayName }}</h2>
          </div>
          <div class="font-body mt-12" v-html="activeMessage.content"></div>
        </template>
      </div>
    </div>
    <template v-else>
      <LogoHeader
        colorLogo="pink"
        :showBackButton="false"
        class="md:invisible"
      />
      <div
        class="not-found w-full h-full flex flex-col justify-center items-center fixed md:absolute"
      >
        <img src="@/assets/img/empty-inbox.svg" alt="no messages" />
        <h3 class="mt-2">{{ $t("message.nothing-in-your-inbox") }}</h3>
        <p class="text-center max-w-message font-body2 mt-4">
          {{ $t("message.inbox-empty-message") }}
        </p>
      </div>
    </template>
  </div>
</template>
<script>
import InboxSkeleton from "@/components/skeletons/InboxSkeleton.vue";
import LogoHeader from "@/components/shared/LogoHeader.vue";

export default {
  components: {
    InboxSkeleton,
    LogoHeader
  },
  data() {
    return {
      loading: true,
      activeMessage: null,
      closedMessage: true,
      inboxMessages: [
        {
          id: 0,
          sender: {
            image:
              "https://gol-appsync-dev-assetsbucket-6l7vk4g3vufx.s3-accelerate.amazonaws.com/9604dc56-5c15-4994-a2e1-86f4d060f502/01FC5KCSDC2X4Q8HXRD59DYTN5.jpg",
            displayName: "Vini Jr"
          },
          subject: "Thanks for fighting Racial Injustice.",
          content:
            "Hey, welcome to Team Maputo United, so happy you joined us to help young Life Captains. Signed Felicienne",
          timeAgo: "just now"
        },
        {
          id: 1,
          sender: {
            image:
              "https://gol-appsync-dev-assetsbucket-6l7vk4g3vufx.s3-accelerate.amazonaws.com/9604dc56-5c15-4994-a2e1-86f4d060f502/01FC5KCSDC2X4Q8HXRD59DYTN5.jpg",
            displayName: "Vini Jr - 2"
          },
          subject: "2 - Thanks for fighting Racial Injustice.",
          content:
            "2 - Hey, welcome to Team Maputo United, so happy you joined us to help young Life Captains. Signed Felicienne",
          timeAgo: "just now"
        },
        {
          id: 2,
          sender: {
            image:
              "https://gol-appsync-dev-assetsbucket-6l7vk4g3vufx.s3-accelerate.amazonaws.com/9604dc56-5c15-4994-a2e1-86f4d060f502/01FC5KCSDC2X4Q8HXRD59DYTN5.jpg",
            displayName: "Vini Jr - 3"
          },
          subject: "3 - Thanks for fighting Racial Injustice.",
          content:
            "3 - Hey, welcome to Team Maputo United, so happy you joined us to help young Life Captains. Signed Felicienne",
          timeAgo: "just now"
        },
        {
          id: 3,
          sender: {
            image:
              "https://gol-appsync-dev-assetsbucket-6l7vk4g3vufx.s3-accelerate.amazonaws.com/9604dc56-5c15-4994-a2e1-86f4d060f502/01FC5KCSDC2X4Q8HXRD59DYTN5.jpg",
            displayName: "Vini Jr - 4"
          },
          subject: "4- Thanks for fighting Racial Injustice.",
          content:
            "4- Hey, welcome to Team Maputo United, so happy you joined us to help young Life Captains. Signed Felicienne",
          timeAgo: "just now"
        },
        {
          id: 4,
          sender: {
            image:
              "https://gol-appsync-dev-assetsbucket-6l7vk4g3vufx.s3-accelerate.amazonaws.com/9604dc56-5c15-4994-a2e1-86f4d060f502/01FC5KCSDC2X4Q8HXRD59DYTN5.jpg",
            displayName: "Vini Jr - 5"
          },
          subject: "5 - Thanks for fighting Racial Injustice.",
          content:
            "5 - Hey, welcome to Team Maputo United, so happy you joined us to help young Life Captains. Signed Felicienne",
          timeAgo: "just now"
        }
      ]
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
  methods: {
    openMessage(message) {
      this.activeMessage = message;
      this.$nextTick(() => {
        this.closedMessage = false;
      });
    }
  }
};
</script>
<style lang="postcss" scoped>
.profile {
  flex: 0 0 72px;
  height: 72px;
  overflow: hidden;
  border-radius: 100%;
}

.profile img {
  width: 100%;
}

.base-grid {
  display: grid;
  grid-template-columns: 100% 0;
}

.divide-grid {
  display: grid;
  grid-template-columns: minmax(300px, 375px) 1fr;
  transition: all 2s;
}

.not-found {
  height: auto;
  top: 50%;
  bottom: 50%;
}

.message-preview-content {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

#messages {
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
}

@media screen and (max-width: 767px) {
  #messages,
  #active-message {
    height: calc(100% - var(--mobile-menu-height));
  }

  #messages.minimize {
    left: -100%;
    transition: all 0.4s;
    animation: animation-message 0.4s linear;
  }

  #active-message {
    width: 100%;
    height: 100%;
    transition: all 0.5s;
    animation: animation-name 0.5s linear;
    position: fixed;
    left: 0%;
  }

  #active-message.inactive {
    left: 100%;
    transition: all 0.5s;
  }

  @keyframes animation-name {
    0% {
      left: 100%;
    }

    100% {
      left: 0%;
    }
  }

  @keyframes animation-message {
    0% {
      left: 0%;
    }

    100% {
      left: -100%;
    }
  }
}

@media screen and (min-width: 768px) {
  #messages {
    position: absolute;
  }

  #messages.minimize {
    width: 50%;
    left: 0;
    transition: all 0.5s;
  }

  #active-message {
    width: 50%;
    height: 100%;
    transition: all 0.5s;
    animation: animation-name 0.5s linear;
    position: absolute;
    left: 50%;
  }

  #active-message.inactive {
    visibility: hidden;
    left: 100%;
  }

  @keyframes animation-name {
    0% {
      left: 100%;
    }

    100% {
      left: 50%;
    }
  }
}
</style>
