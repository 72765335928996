<template>
  <Profile
    :key="$route.params.screenName || $route.params.profileId || 'myProfile'"
  />
</template>

<script>
import Profile from "@/components/profile/Profile.vue";

export default {
  name: "ProfilePage",
  components: {
    Profile
  }
};
</script>
