<template>
  <div class="w-full h-full max-w-desktop mx-auto">
    <BaseConfirmationModal
      v-model="showLogoutModal"
      :loading="logoutLoading"
      :confirmText="$t('message.logout')"
      :cancelText="$t('message.cancel')"
      @actionConfirmed="logout()"
    >
      <div class="flex flex-col text-center items-center justify-center">
        <template>
          <h4>{{ $t("message.logout-gol") }}</h4>
          <div class="font-subtitle mt-7">
            {{ $t("message.do-you-really-want-logout") }}
          </div>
        </template>
      </div>
    </BaseConfirmationModal>
    <SettingsContainer
      :header="$t('message.account-settings')"
      :centerDesktopHeader="false"
    >
      <ul class="h-full w-full">
        <li
          v-if="!isCurrentUserFederated"
          class="w-full h-20 flex justify-center"
        >
          <button
            @click="$router.push({ name: 'ChangePasswordPage' })"
            class="w-full flex flex-row items-center border-b border-t border-gray-200 border-solid text-left"
          >
            <div class="font-subtitle2 text-blue-900 flex-grow">
              {{ $t("message.change-password") }}
            </div>
            <div class="w-14 flex justify-end">
              <img src="@/assets/img/icons/right-arrow.svg" alt="go" />
            </div>
          </button>
        </li>
        <li class="w-full h-20 flex justify-center">
          <button
            @click="$router.push({ name: 'AccountContactPreferencesPage' })"
            class="w-full flex flex-row items-center border-b border-gray-200 border-solid text-left"
          >
            <div class="font-subtitle2 text-blue-900 flex-grow">
              {{ $t("message.contact-preferences.link") }}
            </div>
            <div class="w-14 flex justify-end">
              <img src="@/assets/img/icons/right-arrow.svg" alt="go" />
            </div>
          </button>
        </li>
        <li class="w-full h-20 flex justify-center">
          <a
            class="w-full flex flex-row items-center border-b border-gray-200 border-solid text-left"
            href="https://www.gameofourlives.org/docs/terms-conditions"
          >
            <div class="font-subtitle2 text-blue-900 flex-grow capitalize">
              {{ $t("message.terms-and-conditions") }}
            </div>
          </a>
        </li>
        <li class="w-full h-20 flex justify-center">
          <a
            class="w-full flex flex-row items-center border-b border-gray-200 border-solid text-left"
            href="https://www.gameofourlives.org/docs/privacy-policy"
          >
            <div class="font-subtitle2 text-blue-900 flex-grow capitalize">
              {{ $t("message.privacy-policy") }}
            </div>
          </a>
        </li>
        <li class="w-full h-20 flex justify-center">
          <button
            @click="cookiePreferences"
            class="w-full flex flex-row items-center border-b border-gray-200 border-solid text-left"
          >
            <div class="font-subtitle2 text-blue-900 flex-grow">
              {{ $t("message.cookie-policy-and-preferences") }}
            </div>
          </button>
        </li>
        <li class="w-full h-20 flex justify-center">
          <button
            @click="$router.push({ name: 'HelpAndSupport' })"
            class="w-full flex flex-row items-center border-b border-gray-200 border-solid text-left"
          >
            <div class="font-subtitle2 text-blue-900 flex-grow">
              {{ $t("message.help-and-support") }}
            </div>
            <div class="w-14 flex justify-end">
              <img src="@/assets/img/icons/right-arrow.svg" alt="go" />
            </div>
          </button>
        </li>
        <li class="w-full h-20 flex justify-center">
          <button
            class="w-full flex flex-row items-center border-b border-gray-200 border-solid text-left"
            @click="openLogoutModal"
          >
            <div class="font-subtitle2 text-red-900 flex-grow capitalize">
              {{ $t("message.logout") }}
            </div>
          </button>
        </li>
        <li class="w-full h-20 flex justify-center">
          <div
            class="w-full flex flex-row items-center text-sm text-gray-600 flex-grow capitalize"
          >
            {{ $t("message.app-version") }} {{ appVersion() }}
          </div>
        </li>
      </ul>
    </SettingsContainer>
  </div>
</template>
<script>
import SettingsContainer from "@/components/settings/SettingsContainer.vue";
import { mapGetters } from "vuex";

export default {
  name: "AccountSettings",
  components: {
    SettingsContainer
  },
  data() {
    return {
      profileBackgroundImage: "/test-imgs/background-image.png",
      showLogoutModal: false,
      logoutLoading: false
    };
  },
  computed: {
    ...mapGetters("auth", ["isCurrentUserFederated"]),
    currentLocale() {
      return this.$i18n.locale;
    },
    locales() {
      return this.$i18n.availableLocales;
    }
  },
  methods: {
    openLogoutModal() {
      this.showLogoutModal = true;
      this.logoutLoading = false;
    },
    logout() {
      this.logoutLoading = true;
      this.$store.dispatch("auth/logout");
    },
    changeLocale(newLocale) {
      this.$i18n.locale = newLocale;
      this.$store.dispatch("general/saveUserLocale", newLocale);
    },
    appVersion: () => {
      // eslint-disable-next-line
      const describe = GIT_DESCRIBE;
      return `gol-appsync-vue-${describe.tag} (${describe.suffix})`;
    },
    cookiePreferences: () => {
      if (window?._iub?.cs) {
        window._iub.cs.api.openPreferences();
      } else {
        console.warn("iubenda cookie solution not active in this deployment");
      }
    }
  }
};
</script>
