<template>
  <div id="post-video-creator-parent">
    <BaseConfirmationModal
      v-model="showClosePostConfirmation"
      :confirmText="$t('message.discard')"
      :cancelText="$t('message.cancel')"
      @actionConfirmed="closePostCreatorConfirmed()"
    >
      <div class="flex flex-col text-center flex items-center justify-center">
        <template>
          <h4>{{ $t("message.discard-current-post") }}</h4>
        </template>
      </div>
    </BaseConfirmationModal>
    <div
      id="video-post-creator-render"
      v-if="!newPost"
      class="h-full bg-gray-50 z-10 w-full flex flex-col items-center justify-center px-6 md:rounded-md overflow-hidden"
    >
      <div class="text-left w-full mt-6 w-10 flex justify-initial">
        <img
          @click="closePostCreatorConfirmed()"
          src="@/assets/img/icons/close-arrow-blue.svg"
          alt="close"
        />
      </div>
      <div class="flex-grow flex flex-col justify-center">
        <img
          class="w-44"
          src="@/assets/img/icons/video-camera.svg"
          alt="close"
        />
      </div>
      <PostVideoUploader
        buttonId="select-video-btn"
        @videoUploaded="videoUploaded"
      />
      <div id="footer" class="h-24 w-full items-center flex flex-col">
        <button
          id="select-video-btn"
          class="btn
          btn-default
          w-48"
        >
          {{ $t("message.add-a-video") }}
        </button>
      </div>
    </div>

    <div
      id="video-post-creator-render"
      v-else
      class="h-full bg-black fixed w-full flex z-10 md:overflow-hidden md:rounded-md"
    >
      <video
        class="w-full flex-grow z-0"
        :src="newPost.videoUrl"
        controls
      ></video>
      <div class="fixed w-full flex justify-center absolute top-0 pt-4 pb-4">
        <div id="controls-bg" />
        <div class="absolute right-1 top-4 w-10 flex justify-initial">
          <img
            @click="closePostCreator()"
            src="@/assets/img/icons/exit-icon-white.svg"
            alt="close"
          />
        </div>
        <button
          @click.prevent="confirmPost()"
          class="base-shadow
              btn
              bg-white
              text-black
              border
              border-white
              outline-none
              focus:outline-none
              flex
              text-center
              justify-center
              items-center
              px-6
              z-20"
        >
          <span class="mx-auto">{{ $t("message.post-now") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import PostVideoUploader from "@/components/shared/PostVideoUploader.vue";

export default {
  components: {
    PostVideoUploader
  },
  data() {
    return {
      newPost: null,
      showClosePostConfirmation: false
    };
  },
  methods: {
    closePostCreator() {
      this.showClosePostConfirmation = true;
    },
    closePostCreatorConfirmed() {
      this.$emit("discardAndClose");
    },
    confirmPost() {
      this.$emit("postCreated", this.newPost);
    },
    videoUploaded(newVideoUrl) {
      this.newPost = {
        videoUrl: newVideoUrl
      };
    }
  }
};
</script>
<style lang="postcss" scoped>
#post-video-creator-parent {
  position: fixed;
  z-index: 20;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #000000b5;
  display: flex;
  justify-content: center;
  align-items: center;
}

#video-post-creator-render {
  position: relative;
}

@media screen and (min-width: 600px) {
  #video-post-creator-render {
    max-width: var(--desktop-post-width);
    max-height: var(--desktop-post-height);
  }
}

.mod #controls-bg {
  height: 300px;
  width: 100%;
  z-index: -1;
  background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
  opacity: 0.6;
  transform: matrix(1, 0, 0, -1, 0, 0);
  position: absolute;
  top: 0;
}
</style>
