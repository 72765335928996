<template>
  <div>
    <div class="container mb-2" v-if="$slots.title">
      <h4 class="capitalize text-left">
        <slot name="title"></slot>
      </h4>
    </div>
    <div id="lives-supported" class="w-full bg-gray-200 flex flex-row">
      <div
        class="scrollbar-hidden w-full flex flex-row overflow-x-auto md:justify-center"
      >
        <div class="flex flex-col mr-1 p-4" v-if="coinsEarned">
          <h2>{{ coinsEarned }}</h2>
          <div class="font-tiny whitespace-nowrap">
            {{ $t("message.coins-earned") }}
          </div>
        </div>
        <div class="flex flex-col mr-1 p-4">
          <h2>3.77K</h2>
          <div class="font-tiny whitespace-nowrap">
            {{ $t("message.lives-supported") }}
          </div>
        </div>
        <div class="flex flex-col mr-1 p-4">
          <h2>3.76K</h2>
          <div class="font-tiny whitespace-nowrap">
            {{ $t("message.lives-supported") }}
          </div>
        </div>
        <div class="flex flex-col mr-1 p-4">
          <h2>3.75K</h2>
          <div class="font-tiny whitespace-nowrap">
            {{ $t("message.lives-supported") }}
          </div>
        </div>
        <div class="flex flex-col mr-1 p-4">
          <h2>3.74K</h2>
          <div class="font-tiny whitespace-nowrap">
            {{ $t("message.lives-supported") }}
          </div>
        </div>
        <div class="flex flex-col mr-1 p-4">
          <h2>4.74K</h2>
          <div class="font-tiny whitespace-nowrap">
            {{ $t("message.lives-supported") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    coinsEarned: {
      type: Number,
      required: false,
      default: 0
    }
  }
};
</script>
