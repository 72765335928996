<template>
  <section class="section-join-team ">
    <!--     height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center; -->
    <div
      class="relative h-full container flex flex-col justify-center align-center mx-auto"
    >
      <h2 class="self-center">
        Join a team
      </h2>
      <button
        class="self-center w-56 bg-black text-white rounded-full uppercase p-6"
        @click="$router.push({ name: 'Register' })"
      >
        Get started
      </button>
      <img
        src="@/assets/img/home/face1.png"
        alt=""
        class="absolute rounded-full image1"
      />
      <img
        src="@/assets/img/home/face2.png"
        alt=""
        class="absolute rounded-full image2"
      />
      <img
        src="@/assets/img/home/face3.png"
        alt=""
        class="absolute rounded-full image3"
      />
      <img
        src="@/assets/img/home/face4.png"
        alt=""
        class="absolute rounded-full image4"
      />
      <img
        src="@/assets/img/home/face5.png"
        alt=""
        class="absolute rounded-full image5"
      />
      <img
        src="@/assets/img/home/face6.png"
        alt=""
        class="absolute rounded-full image6"
      />
      <img
        src="@/assets/img/home/face7.png"
        alt=""
        class="absolute rounded-full image7"
      />
      <img
        src="@/assets/img/home/face8.png"
        alt=""
        class="absolute rounded-full image8"
      />
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
.section-join-team {
  height: 919px;
  background-color: #f6e47e;
}

.image1 {
  top: 80px;
  left: 80px;
  background: #9f0009;
}

.image2 {
  top: 220px;
  left: 259px;
  background: #6995e7;
}

.image3 {
  top: 294px;
  left: 40px;
  background: #e2e2db;
}

.image4 {
  top: 418px;
  left: 289px;
  background: #e2e2db;
}

.image5 {
  top: 80px;
  right: 125px;
  background: #ec677a;
}

.image6 {
  top: 270px;
  right: 256px;
  background: #99e192;
}

.image7 {
  top: 399px;
  right: 48px;
  background: #a0a099;
}

.image8 {
  top: 463px;
  right: 324px;
  background: #fce8eb;
}
</style>
