var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.task.id,staticClass:"task flex flex-col w-48 mr-4 md:mr-6 justify-start mb-2 cursor-pointer"},[_c('router-link',{staticClass:"image rounded-sm w-48 relative bg-no-repeat bg-center bg-cover overflow-hidden",style:(("background-image: url('" + (_vm.task.coverImage.url) + "')")),attrs:{"to":{
      name: 'TaskPage',
      params: {
        teamId: _vm.task.progress.teamId,
        taskId: _vm.task.progress.taskId
      }
    }}},[_c('div',{staticClass:"bg-shadow"}),(_vm.task.progress.status === 'complete')?_c('TaskStatusBadge',{attrs:{"taskType":_vm.task.taskType,"status":_vm.task.progress.status,"count":_vm.task.progress.taskCompletionsCount}}):_vm._e(),(!_vm.task.note)?_c('h5',{staticClass:"text-gray-50 capitalize w-full text-center bottom-4 absolute"},[_vm._v(" "+_vm._s(_vm.task.coverHeading)+" ")]):_vm._e()],1),_c('div',{staticClass:"h-24"},[_c('LivesImpactedPill',{staticClass:"mt-2",attrs:{"text":_vm.getPointsText(_vm.task),"showInfo":false}}),_c('router-link',{attrs:{"to":{
        name: 'TaskPage',
        params: {
          teamId: _vm.task.progress.teamId,
          taskId: _vm.task.progress.taskId
        }
      }}},[_c('h5',{staticClass:"text-black h-10 mt-2 text-sm",attrs:{"id":"task-title"}},[_vm._v(" "+_vm._s(_vm.task.coverContent)+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }